<template>
  <div>
    <v-list subheader one-line dense style="padding-bottom: 0px !important;" :style="{background: $wiDark ? '' : 'white'}">
      <v-list-tile avatar @click="setShowStageContent()">
        <v-list-tile-avatar style="padding-right: 10px;">
          <v-avatar>
            <v-icon large color="black">
              {{ stage.stage.type.icon || 'add' }}
            </v-icon>
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title style="font-size: 16px;">
            <strong>{{ stage.stage.name }}</strong>
          </v-list-tile-title>
          <v-list-tile-sub-title> {{ stage.stage.type.name }} </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action class="hidden-xs-only hidden-sm-only">
          <v-list-tile-action-text>
            <span>
              {{ execution.id }} - 
            </span>
          </v-list-tile-action-text>
        </v-list-tile-action>
        <v-list-tile-action v-if="openedAt">
          <v-list-tile-action-text>
            <span>
              {{ openedAt | moment('DD/MM/YYYY HH:mm:ss') }}
            </span>
          </v-list-tile-action-text>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-list-tile-action-text v-on="on">
                <span v-text="getExternalTimeFormated(openedAt)"></span>
              </v-list-tile-action-text>
            </template>
            <span>
              {{ openedAt | moment('DD/MM/YYYY HH:mm:ss') }}
            </span>
          </v-tooltip> -->
        </v-list-tile-action>
        <v-list-tile-action v-if="status.code == 'closed' || status.code == 'canceled'" style="padding-left: 10px; padding-top: 0 !important; padding-bottom: 0 !important;">
        <v-icon v-if="!showStageContent">expand_more</v-icon>
        <v-icon v-else>expand_less</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <v-divider v-if="showStageContent"></v-divider>
    <v-divider v-if="showStageContent"></v-divider>
  </div>
</template>
<script>
  export default {
    name: 'ProcessStageHeader',
    methods: {
      setShowStageContent: function () {
        this.$emit('setShowStageContent', !this.showStageContent)
      },
      getExternalTimeFormated: function (time) {
        if (time) {
          const date = this.$moment(time)
          const today = this.$moment().startOf('day')
          const days = Math.round(this.$moment.duration(today - date).asDays())
          if (days > 1) {
            return this.$moment(time).format('DD/MM/YY HH:mm')
          }
          return this.$moment(time).fromNow()
        }
        return 'Agora'
      }
    },
    props: {
      stage: {
        type: Object,
        required: true
      },
      execution: {
        type: Object,
        required: true
      },
      status: {
        type: Object,
        default: () => ({})
      },
      showStageContent: {
        type: Boolean,
        required: true
      },
      openedAt: {
        type: String,
        default: null
      }
    }
  }
</script>
