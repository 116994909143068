<template>
  <div>
    <view-detail
      title="Cadastro" :apiUrl="apiUrl" :store="store"
      :apiParams="'?with=tags;phones;contacts;adresses;infos;users;users.profile;files;talk_contacts;talk_contacts.file'"
    >
      <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
        <v-btn v-prms="{'id': '93dd92fc'}" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="profilesFunctions({ content, refresh, loading })" :disabled="loading">
          <v-icon>recent_actors</v-icon>
          <span class="ml-2">Funções/Papeis</span>
        </v-btn>
        <v-btn v-if="portal && $route.name == 'customer' && content && content.access_area && content.email && content.uuid" class="hidden-xs-only hidden-sm-only" :loading="portalLoading" small outline color="white" :disabled="loading" @click="accessPortal(content)">
          <v-icon>launch</v-icon>
          <span>
            <span>Acessar </span> 
            <span v-if="$WiCheckPermission('85352c3c-1321-4cba-b009-d5c4dc909159')"> área do paciente </span>
            <span v-else-if="$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088')"> área do formando </span>
            <span v-else> área do cliente </span>
          </span>
        </v-btn>
      </template>
      <template slot-scope="{ content, refresh }">
        <v-container>
          <v-layout v-if="content && content.tags && content.tags.length > 0" row class="mb-3">
            <v-flex xs12>
              <v-card class="elevation-17">
                <v-card-text>
                  <v-layout row>
                    <v-flex xs12>
                      <strong>Tags: </strong>
                      <v-chip
                        v-for="tag in content.tags"
                        :key="tag.id" large
                        close @input="removeTag(tag, refresh)"
                        :color="tag.color" class="ma-1 elevation-1"
                      >
                        <span :style="{ color: getTextColoe(tag.color) }">
                          {{ tag.name }}
                        </span>
                      </v-chip>
                      <!-- <v-spacer></v-spacer>
                      <v-btn outline small @click="addTag(content, refresh)">
                        <v-icon>add</v-icon>
                        <span v-t="'modules.talks.pages.talkView.talk.labels.addTag'"></span>
                      </v-btn> -->
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-card class="elevation-17">
                <PersonInfo
                  :person="content"
                  :refresh="refresh"
                ></PersonInfo>
              </v-card>
            </v-flex>
          </v-layout>
          <PersonGroups
            v-if="content && content.groups"
            :person="content"
          ></PersonGroups>
          <PersonStatistics
            v-if="content && content.statistics"
            :person="content"
          ></PersonStatistics>
          <PersonTabs
            :person="content"
            :refresh="refresh"
          ></PersonTabs>
        </v-container>
      </template>
    </view-detail>
  </div>
</template>

<script>
  import { callApi } from '@/default/service/Api'
  import PersonInfo from '@/modules/register/pages/Person/PersonInfo'
  import PersonGroups from '@/modules/register/pages/Person/PersonGroups'
  import PersonStatistics from './PersonStatistics/index'
  import PersonTabs from './PersonTabs/index'
  export default {
    name: 'Person',
    data () {
      return {
        apiUrl: '',
        apiParams: '?with=contacts;adresses;infos;users',
        store: null,
        tabAtiva: 'tab-1',
        portalLoading: false
      }
    },
    created: function () {
      this.loadPessoa()
    },
    watch: {
      '$route': 'loadPessoa'
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      },
      portal () {
        return this.$Auth.account().portal
      }
    },
    methods: {
      loadPessoa: function () {
        this.apiUrl = 'register/person/' + this.$route.name
        this.store = this.$route.name
      },
      accessPortal: function (person) {
        this.portalLoading = true
        console.log(this.portal);
        callApi.post({
          uri: 'portal-login-super/' + person.uuid,
          data: {
            email: person.email
          },
          sucess: response => {
            if (response?.data?.status === 'success' && response?.data?.token) {
              window.open(this.portal + '/#/dd18fa5b-3818-4177-a2cf-151b90cd58c8/' + response.data.token, '_blank')
            } else {
              this.$swal({
                type: 'warning',
                title: 'Erro ao buscar credenciais.',
                html: 'Tente novamente ou contacte o suporte.'
              })
            }
            this.portalLoading = false
          },
          error: error => {
            console.log(error)
            this.portalLoading = false
          }
        })
      },
      getTextColoe: function (corHexadecimal) {
          // Converte a cor hexadecimal para RGB
          const r = parseInt(corHexadecimal.slice(1, 3), 16);
          const g = parseInt(corHexadecimal.slice(3, 5), 16);
          const b = parseInt(corHexadecimal.slice(5, 7), 16);

          // Calcula o valor de luminosidade usando a fórmula YIQ
          const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

          // Define um valor de limiar para determinar a cor do texto
          const limiar = 128;

          // Verifica se a luminosidade é maior que o limiar
          if (luminosidade > limiar) {
              // Se a cor de fundo for clara, retorna cor escura
              return "#000000"; // Cor preta
          } else {
              // Se a cor de fundo for escura, retorna cor clara
              return "#FFFFFF"; // Cor branca
          }
      },
      addTag: function (person, refresh) {
        if (person?.id) {
          this.$WiListDialog({
            wiConfig: 'tag',
            callback: (tags) => {
              this.$WiApiPost({
                uri: 'register/person/tag',
                data: {
                  multiple: Array.isArray(tags) ? tags.map(tag => ({
                    person_id: person.id,
                    tag_id: tag.id
                  })) : [{
                    person_id: person.id,
                    tag_id: tags.id
                  }]
                },
                config: {
                  msgLoad: {
                    title: `Adicionando tag(s)`,
                    text: `Aguarde enquanto as tag(s) são adicionadas.`
                  },
                  msgSucess: {
                    title: 'Tag(s) adicionadas com sucesso!',
                    text: 'As tag(s) foram adicionadas com sucesso.'
                  },
                  msgError: {
                    title: 'Erro ao adicionar tag(s)',
                    text: 'Tente novamente ou contacte o suporte.'
                  }
                },
                callback: () => {
                  if (refresh) {
                    refresh()
                  }
                }
              })
            },
            mode: 'select'
          })
        } else {
          this.setPersonProfiledialog = true
        }
      },
      removeTag: function (tag, refresh) {
        this.$WiApiDelete({
          uri: `register/person/tag`,
          id: tag.person_tag_id,
          config: {
            msgLoad: {
              title: `Removendo tag`,
              text: `Aguarde enquanto a tag é removida.`
            },
            msgSucess: {
              title: 'Tag removida com sucesso!',
              text: 'A tag foi removida com sucesso.'
            },
            msgError: {
              title: 'Erro ao remover tag',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: false
          },
          callback: () => {
            if (refresh) {
              refresh()
            }
          }
        })
      },
      profilesFunctions: function ({ content, refresh }) {
        const profilesEditorData = {
          id: content.id,
          type: content.type,
          customer: 'no',
          provider: 'no',
          collaborator: 'no',
          prospect: 'no',
          company: 'no'
        }
        content.profiles.forEach(profile => {
          profilesEditorData[profile.name] = profile.status
        })
        this.$WiEditDialog({
          wiConfig: this.store + '-function', 
          onSubmit: () => {
            if (refresh) {
              refresh()
            }
          }, 
          data: profilesEditorData
        })
      }
    },
    components: {
      PersonTabs,
      PersonInfo,
      PersonGroups,
      PersonStatistics
    }
  }
</script>