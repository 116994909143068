<template>
  <td class="justify-center ">
    <div v-if="config.mode != 'select'" style="min-width: 80px !important;">
      <v-btn
        v-if="checkButtonConfig('update')"
        @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: props.item})" 
        v-prms="{'id': getIdPermission('update', config.config), mode: 'disabled'}" 
        slot="active" 
        icon 
        class="mx-0 wrapper"
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <db-delete 
        v-if="checkButtonConfig('delete')" 
        v-prms="{'id': getIdPermission('delete', config.config), mode: 'disabled'}" 
        class="mx-0 wrapper" 
        v-on:action="config.refresh" 
        :store="config.store" 
        :id="props.item.id"
        :icon="deleteIcon"
      ></db-delete>
    </div>
    <div v-else>
      <v-btn 
        flat 
        icon
        @click="config.select(props.item)" 
        class="select-action-btn mx-0"
      >
        <v-icon>check</v-icon>
      </v-btn>
    </div>
  </td>
</template>
<script>
  export default {
    name: 'WiListDataTableActions',
    methods: {
      getIdPermission: function (action, config) {
        if (config && config.id) {
          return config.id[action] || config.id
        }
        return null
      },
      checkButtonConfig: function (action) {
        var active = true
        if (this.config.config.actions) {
          if (typeof this.config.config.actions[action] === 'function') {
            active = this.config.config.actions[action](this.props.item)
          } else {
            active = this.config.config.actions[action]
          }
        }
        return active
      }
    },
    props: {
      config: Object,
      props: Object,
      deleteIcon: String
    }
  }
</script>
<style scoped>
  .permission_disabled >>> * {
    color: #B0B0B0 !important;
  }

  .select-action-btn {
    position: relative;
    border: none !important;
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                background 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
    width: 34px !important;
    height: 34px !important;
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  /* Modo claro */
  .theme--light .select-action-btn {
    background: #e8f5e9 !important;
    border: 1px solid #81c784 !important;
  }

  .theme--light .select-action-btn i {
    color: #2e7d32 !important;
  }

  .theme--light .select-action-btn:hover {
    background: #66bb6a !important;
    border-color: #66bb6a !important;
    box-shadow: 0 2px 8px rgba(76, 175, 80, 0.25) !important;
    transform: translateY(-1px);
  }

  /* Modo escuro */
  .theme--dark .select-action-btn {
    background: rgba(76, 175, 80, 0.15) !important;
    border: 1px solid rgba(129, 199, 132, 0.5) !important;
  }

  .theme--dark .select-action-btn i {
    color: #81c784 !important;
  }

  .theme--dark .select-action-btn:hover {
    background: rgba(76, 175, 80, 0.25) !important;
    border-color: #66bb6a !important;
    box-shadow: 0 2px 8px rgba(76, 175, 80, 0.15) !important;
    transform: translateY(-1px);
  }

  /* Efeitos no hover */
  .select-action-btn i {
    position: relative;
    font-size: 20px !important;
    font-weight: 500 !important;
    transition: all 0.2s ease !important;
  }

  .select-action-btn:hover i {
    color: #ffffff !important;
    transform: scale(1.1);
  }

  .select-action-btn:active {
    transform: translateY(1px);
  }

  .select-action-btn:active i {
    transform: scale(0.95);
  }

  /* Estados desabilitados */
  .theme--light .permission_disabled >>> .select-action-btn {
    background: #f5f5f5 !important;
    border-color: #e0e0e0 !important;
  }

  .theme--dark .permission_disabled >>> .select-action-btn {
    background: rgba(255, 255, 255, 0.05) !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
  }

  .permission_disabled >>> .select-action-btn {
    cursor: not-allowed !important;
  }

  .theme--light .permission_disabled >>> .select-action-btn i {
    color: #bdbdbd !important;
  }

  .theme--dark .permission_disabled >>> .select-action-btn i {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  .permission_disabled >>> .select-action-btn:hover {
    transform: none !important;
    box-shadow: none !important;
  }
</style>