
import BancoPan from './BancoPan/BancoPan'
import BancoBmg from './BancoPan/BancoPan'
import BancoMaster from './Master/BancoMaster'
import BancoDaycoval from './Master/BancoMaster'
import BancoFacta from './Master/BancoMaster'

export default [
  {
    id: 1,
    name: 'Banco Master',
    code: 'bancoMaster1',
    logo: '/static/integration/logos/banco-master.png',
    fields: BancoMaster,
  },
  {
    id: 2,
    name: 'Banco Pan',
    code: 'bancoPan',
    logo: '/static/integration/logos/banco-pan.webp',
    fields: BancoPan,
  },
  {
    id: 3,
    name: 'Banco Daycoval',
    code: 'bancoDaycoval',
    logo: '/static/integration/logos/banco-daycoval.png',
    fields: BancoDaycoval,
  },
  {
    id: 4,
    name: 'Banco Facta',
    code: 'bancoFacta',
    logo: '/static/integration/logos/banco-facta.png',
    fields: BancoFacta,
  },
  {
    id: 5,
    name: 'Banco BMG',
    code: 'bancoBmg',
    logo: '/static/integration/logos/banco-bmg.png',
    fields: BancoBmg,
  }
]
