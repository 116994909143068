<template>
  <v-list-tile-action>
    <v-list-tile-action-text>
      <span class="talks-list-item-action-text">
        {{ getExternalTimeFormated(talk.last_event_at) }}
      </span>
    </v-list-tile-action-text>
    
    <span>
      <span
        v-text="talk.time_total"
        class="talks-list-item-action-text"
        style=""
      ></span>
      <strong
        v-if="showUnseenMessages && talk.status < 3"
        v-text="talk.unseen_messages"
        class="talks-unseen-messages-count elevation-1"
      />
    </span>
    
    <span>
      <v-tooltip v-if="talk.is_waiting_response && talk.status < 3" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on" 
            color="orange" 
            small
            style="font-size: 14px;"
          >notification_important</v-icon>
        </template>
        <span>Aguardando resposta</span>
      </v-tooltip>
      
      <TalkMenu
        :talk="talk"
        color="grey lighten-1"
        :small="true"
        :refresh="onRefresh"
      />
    </span>
  </v-list-tile-action>
</template>

<script>
import TalkMenu from '@/modules/talk/pages/TalkView/Talk/TalkMenu'

export default {
  name: 'TalkListItemActions',
  
  components: {
    TalkMenu
  },

  props: {
    talk: {
      type: Object,
      required: true
    },
    currentRouteId: {
      default: null
    },
    onRefresh: {
      type: Function,
      required: true
    }
  },

  computed: {
    showUnseenMessages() {
      return parseInt(this.talk.unseen_messages) && 
             parseInt(this.talk.unseen_messages) !== 0 && 
             this.talk.id !== this.currentRouteId
    }
  },

  methods: {
    getExternalTimeFormated(time) {
      if (!time) return 'Agora'

      const date = this.$moment(time)
      const today = this.$moment().startOf('day')
      const days = Math.round(this.$moment.duration(today - date).asDays())
      
      if (days > 1) {
        return this.$moment(time).format('DD/MM HH:mm')
      }
      
      const ago = this.$moment(time).fromNow()
      let timeMessage = ago.includes('há poucos segundos') ? 'há segundos' : ago
      timeMessage = timeMessage.replaceAll('minutos', 'min')
      timeMessage = timeMessage.replaceAll('segundos', 'seg')
      timeMessage = timeMessage.replaceAll('horas', 'hr')

      return timeMessage
    }
  }
}
</script>

<style scoped>
.talks-unseen-messages-count {
  padding: 0 5px;
  background: #009600;
  color: white;
  border-radius: 20px;
  margin-right: 0px;
  margin-left: 5px;
}

.talks-list-item-action-text {
  font-size: 11px;
  color: grey;
  /* padding-right: 5px; */
}
</style> 