import FinanceCategoryFields from '@/modules/finance/config/Finance/FinanceCategory/FinanceCategoryFields'

export default {
  id: '1b113a91',
  list: true,
  title: {
    list: 'Categorias de Despesas',
    edit: {
      insert: 'Categoria de Despesa',
      update: 'Categoria de Despesa'
    }

  },
  icon: 'category',
  apiUrl: 'finance/category/out',
  maxWidth: '370px',
  fields: FinanceCategoryFields
}
