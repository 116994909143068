<template>
  <div>
    <div v-if="historyTalks.length > 0">
      <v-progress-linear
        :indeterminate="true"
        v-if="loadings && loadings.history"
        style="margin: 1px 0 0 0 !important;"
      ></v-progress-linear>
      <TalksList type="history"></TalksList>
    </div>
    <div v-else-if="loadings.history">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <br><br>
        <div style="margin: 20px 20px; display: flex; gap: 10px; flex-direction: column; justify-content: center; align-items: center;">
          <v-icon size="100">
            history
          </v-icon>
          <h2
            v-t="'modules.talks.pages.talkView.talks.messages.loading.history'"
          ></h2>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="searchTerm" class="search-feedback" :style="{ color: $wiDark ? 'white' : 'black' }">
        <span>Filtrando por "</span>
        <span class="search-term">{{ searchTerm }}</span>
        <span>"</span>
      </div>
      
      
      <div v-else class="talks-list__not-message">
        <div class="talks-list__not-message-content">
          <img
            src="static/background/whatsapp-not-found.png?url=temp1"
            style="max-width: 150px;" alt=""
          />
          <div class="talks-list__not-message-text">
            {{ searchTerm ? 'Nenhum histórico encontrado para esta busca' : $t('modules.talks.pages.talkView.talks.messages.notFound.history') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TalksList from '@/modules/talk/pages/TalkView/Talks/TalksList'

export default {
  name: 'TalksChannels',
  data: () => ({
    pageHeight: innerHeight,
    talkDialog: false,
    currentTalkId: null
  }),
  computed: {
    ...mapState('Talks', ['selectedChannel', 'loadings']),
    historyTalks () {
      return this.$store.getters['Talks/historyTalks']
    }
  },
  methods: {
    ...mapActions('Talks', ['paginateTalks', 'getHistoryTalks', 'setTalksFilters']),
    updateView: function () {
      setInterval(() => {
        this.$forceUpdate()
      }, 78000)
    },
    channelAction: function (channel) {
      this.SELECTED_CHANNEL(channel)
      this.paginateTalks()
    }
  },
  mounted: function () {
    this.getHistoryTalks()
    this.updateView()
  },
  destroyed: function () {
    this.setTalksFilters({})
  },
  props: {
    searchTerm: {
      type: String,
      default: ''
    }
  },
  components: {
    TalksList
  }
}
</script>

<style scoped>
.talks-list .v-list__tile {
  padding: 0 5px 0 5px !important;
}
.talks-list .v-list__tile__avatar {
  /* min-width: 70px !important; */
}
.talks-list__not-message {
  align-items: center;
}
.talks-list__not-message-content {
  padding: 50px 40px;
  text-align: -webkit-center;
  font-size: 20px;
}
.talks-list__not-message img {
  width: 100%;
}
.talks-list__not-message-text {
  padding-bottom: 20px;
}
.status-text {
  font-size: 9px;
  line-height: 1;
  margin-top: 2px;
  white-space: nowrap;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-list__tile__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px !important;
}
.talks-channel-list-item >>> .v-list__tile {
  padding: 0 5px !important;
}
.channel-name {
  display: inline-block;
  padding: 1px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0073529412em;
  white-space: nowrap;
  text-transform: none;
  margin-bottom: 5px;
}

.search-feedback {
  padding: 8px 16px;
  color: white;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  font-weight: 400;
  opacity: 0.9;
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgb(179 179 179 / 35%);
  border-radius: 4px;
  margin: 8px 16px;
}

.search-term {
  color: #2196F3;
  font-weight: 500;
  background: rgba(33, 150, 243, 0.1);
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

/* Ajuste para quando houver busca ativa */
.talks-list {
  height: calc(v-bind(pageHeight) - 60px) !important;
}
</style> 