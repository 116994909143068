import { Model } from '@/default/model/Model'

export class PersonFunction extends Model {
  constructor (field, name) {
    super()
    this.id = '93dd92fc'
    this.title = 'Editar perfis/papeis do ' + name
    this.apiUrl = 'register/person-profiles'
    this.icon = 'recent_actors'
    this.maxWidth = '580px'
    this.setFields([
      this.fieldSubHeader({
        text: `
          <div style="color: #333; font-family: Arial, sans-serif; padding: 2px; background: #f5f5f5; border-radius: 8px; margin-bottom: 15px;">
            <p style="margin: 0 0 12px 0; line-height: 1.5;">
              O cadastro de <strong>${name}</strong> pode ter múltiplos perfis no sistema, permitindo diferentes níveis de acesso e funcionalidades. Configure abaixo os perfis desejados:
            </p>
            <ul style="margin: 0; padding-left: 20px; list-style-type: disc;">
              ${field !== 'customer' ? `<li style="margin-bottom: 8px; color: #555;">Cliente: Para pessoas que compram produtos/serviços</li>` : ''}
              ${field !== 'provider' ? `<li style="margin-bottom: 8px; color: #555;">Fornecedor: Para cadastro de fornecedores de produtos/serviços</li>` : ''}
              ${field !== 'collaborator' ? `<li style="margin-bottom: 8px; color: #555;">Colaborador: Para funcionários e prestadores de serviço</li>` : ''}
              ${field !== 'prospect' ? `<li style="margin-bottom: 8px; color: #555;">Prospect: Para potenciais clientes em processo de negociação</li>` : ''}
              ${field !== 'company' ? `<li style="margin-bottom: 8px; color: #555;">Empresa: Para cadastro de matriz ou filiais</li>` : ''}
            </ul>
            <p style="margin: 12px 0; line-height: 1.5; color: #2196F3; background: #E3F2FD; padding: 2px 8px; border-radius: 4px; border-left: 4px solid #2196F3;">
              <strong>Dica:</strong> É possível ativar vários perfis simultaneamente! Por exemplo, um colaborador também pode ser cliente.
            </p>
          </div>
        `,
        style: {
          padding: '0 2px',
          height: '218px'
        }
      }),
      this.fieldSubHeader({
        text: `<div style="color: #000; font-family: Arial, sans-serif; padding: 8px 0;">
          Selecione abaixo quais papéis/perfis deseja atribuir para <strong>${name}</strong>. Para cada opção, você poderá definir se está ativo ou inativo no sistema.
        </div>`,
        style: {
          height: '58px',
          padding: '0 15px'
        }
      }),
      this.fieldSelect({
        value: 'customer',
        text: 'Este(a) ' + name + ' também é um cliente?',
        placeholder: 'Este(a) ' + name + ' também é um cliente?',
        size: ['xs12', 'sm2', 'md6', 'lg6'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'customer'
        }
      }),
      this.fieldSelect({
        value: 'provider',
        text: 'Este(a) ' + name + ' também é um fornecedor?',
        placeholder: 'Este(a) ' + name + ' também é um fornecedor?',
        size: ['xs12', 'sm2', 'md6', 'lg6'],  
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'provider'
        }
      }),
      this.fieldSelect({
        value: 'collaborator',
        text: 'Este(a) ' + name + ' também é um colaborador?',
        placeholder: 'Este(a) ' + name + ' também é um colaborador?',
        size: ['xs12', 'sm2', 'md6', 'lg6'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: (form) => {
          return form.type === 'Fisica' && field !== 'collaborator'
        }
      }),
      this.fieldSelect({
        value: 'prospect',
        text: 'Este(a) ' + name + ' também é um prospect?',
        placeholder: 'Este(a) ' + name + ' também é um prospect?',
        size: ['xs12', 'sm2', 'md6', 'lg6'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'prospect'
        }
      }),
      this.fieldSelect({
        value: 'company',
        text: 'Este(a) ' + name + ' também é uma Empresa (Matriz ou Filial)?',
        placeholder: 'Este(a) ' + name + ' também é uma Empresa (Matriz ou Filial)?',
        size: ['xs12', 'sm2', 'md6', 'lg6'],
        options: [
          { value: 'no', text: 'Não' },
          { value: 'active', text: 'Sim e está Ativo' },
          { value: 'inactive', text: 'Sim e está Inativo' }
        ],
        showField: () => {
          return field !== 'company'
        }
      })
    ])
  }
}
