import { BusinessFilters } from "@/modules/business/config/BusinessFilters"


export class DiagnosticFilters extends BusinessFilters {
  constructor () {
    super()
    this.availableFields.status_id.apiUrl = 'networks/config/status/test-management-vtal'
    this.availableFields.category_id.apiUrl = 'networks/config/category/test-management-vtal'

    this.fields = [
      this.availableFields.subHeader1,
      this.availableFields.status_id,
      this.availableFields.category_id,
      this.availableFields.external_code,
      this.availableFields.person_id,
      this.availableFields.collaborator_id,
      this.availableFields.subHeader2,
      this.availableFields.dateField,
      this.availableFields.dateStart,
      this.availableFields.dateEnd
    ]
  }
}
