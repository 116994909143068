import {
  PERMISSION_REGISTER_MODULE,
  PERMISSION_REGISTER_IMPORT_PRODUCTS,
  PERMISSION_REGISTER_IMPORT_PRODUCTS_FROM_XLSX
} from '@/default/constants/permissions'

export default {
  id: PERMISSION_REGISTER_MODULE,
  name: 'product',
  list: true,
  title: {
    list: 'Cadastro de Produtos',
    edit: {
      create: 'Novo Produto',
      update: 'Atualizar Produto'
    }
  },
  apiUrl: 'register/product',
  apiParams: {
    limit: 50
  },
  search: {
    placeholder: 'Pesquise ID ou nome do produto',
    fields: ['id', 'name']
  },
  maxWidth: '1000px',
  components: {
    list: {
      tableRow: () => import('@/modules/register/pages/Items/Items')
    }
  },
  imports: {
    permission: PERMISSION_REGISTER_IMPORT_PRODUCTS,
    title: 'Importar',
    origins: {
      imanager: {
        title: 'Importar do Excel',
        permission: PERMISSION_REGISTER_IMPORT_PRODUCTS_FROM_XLSX,
        description: 'Baixe o modelo de planilha e importe para o sistema.',
        image: '/static/icons/excel_icon.png',
        wiConfig: 'import-product-excel'
      }
    }
  },
  fields: [
    {
      value: 'id',
      text: 'ID',
      search: true,
      type: 'int',
      list: true,
      edit: false,
      align: 'center'
    },
    {
      text: 'Informe os dados básicos deste produto.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'name',
      text: 'Nome',
      counter: 100,
      search: true,
      placeholder: 'Informe o nome em no máximo 100 caracteres.',
      type: 'text',
      size: [
        'xs12',
        'sm12',
        'md6',
        'lg6'
      ],
      required: true,
      list: true,
      edit: true,
      align: 'center'
    },
    {
      value: 'unit.full_name',
      text: 'Unidade de medida',
      search: true,
      type: 'text',
      list: true,
      edit: false,
      align: 'center'
    },
    {
      value: 'amount_sale',
      text: 'Preço de Venda',
      search: true,
      type: 'text',
      list: true,
      edit: false,
      align: 'center'
    },
    {
      value: 'id',
      text: 'Opções',
      type: 'text',
      list: true
    },
    {
      value: 'item_unit_id',
      text: 'Unidade de medida*',
      required: true,
      autocomplete: true,
      size: [
        'xs12',
        'sm12',
        'md4',
        'lg4'
      ],
      type: 'db-autocomplete',
      list: false,
      edit: true,
      apiUrl: 'register/product/unit',
      apiParams: {
        fields: 'id,name,code',
        order: 'code,asc'
      },
      show: 'full_name'
    },
    {
      value: 'active',
      text: 'Ativo',
      required: false,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'switch',
      list: false,
      edit: true
    },
    {
      value: 'amount_cost',
      text: 'Preço de Custo',
      type: 'money',
      edit: true,
      size: [
        'xs12',
        'sm12',
        'md4',
        'lg4'
      ]
    },
    {
      value: 'margin',
      text: 'Margem de Lucro (%)',
      placeholder: 'Ex: 10 (10%)',
      type: 'int',
      edit: true,
      step: '.01',
      size: [
        'xs12',
        'sm12',
        'md4',
        'lg4'
      ]
    },
    {
      value: 'amount_sale',
      text: 'Preço de Venda',
      type: 'money',
      edit: true,
      size: [
        'xs12',
        'sm12',
        'md4',
        'lg4'
      ]
    },
    {
      text: 'O campo abaixo pode ser usado para registrar observações sobre este produto.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'description',
      text: 'Observações do produto',
      placeholder: 'Por favor, descreva este chamado de forma detalhada.',
      type: 'textarea',
      list: false,
      edit: true
    }
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
