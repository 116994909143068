import Vue from 'vue'
import axios from 'axios'

export default {
  type: 'icon',
  color: 'transparent',
  icon: 'search',
  text: 'Buscar CEP',
  disabled: (form) => {
    return !form.zip_code || form.zip_code.length < 8
  },
  loading: (form) => {
    return form.zipCodeLoading
  },
  action: ({ form, setField }) => {
    setField({ field: 'zipCodeLoading', data: true })
    axios.get('https://viacep.com.br/ws/' + form.zip_code + '/json/')
    .then(response => {
      if (response.data.erro) {
        Vue.swal({
          type: 'warning',
          title: 'O CEP "' + form.zip_code + '" não foi localizado no banco de dados dos Correios.',
          text: 'Por favor, insira os dados do endereço manualmente.'
        })
      }
      setField({ field: 'address', data: response.data.logradouro })
      setField({ field: 'district', data: response.data.bairro })
      setField({ field: 'iso', data: parseInt(response.data.ibge) })
      setField({ field: 'complement', data: response.data.complemento })
      setField({ field: 'zipCodeLoading', data: false })
    })
    .catch(error => {
      console.log(error)
      setField({ field: 'zipCodeLoading', data: false })
      Vue.swal({
        type: 'error',
        title: 'Erro ao buscar CEP',
        text: 'Por favor, tente novamente.'
      })
    })
  }
}
