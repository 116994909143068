<template>
  <div>
    <div v-if="selectedChannel">
      <v-progress-linear
        :indeterminate="true"
        v-if="loadings && loadings.paginate"
        style="margin: 1px 0 0 0 !important;"
      ></v-progress-linear>
      <TalksList type="by-channel"></TalksList>
    </div>
    <div v-else-if="loadings.channels">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <br><br>
        <div style="margin: 20px 20px; display: flex; gap: 10px; flex-direction: column; justify-content: center; align-items: center;">
          <v-icon size="100">
            phone_iphone
          </v-icon>
          <h2
            v-t="'modules.talks.pages.talkView.talks.messages.loading.channels'"
          ></h2>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="searchTerm" class="search-feedback" :style="{ color: $wiDark ? 'white' : 'black' }">
        <span>Filtrando por "</span>
        <span class="search-term">{{ searchTerm }}</span>
        <span>"</span>
      </div>
      
      <div v-if="filteredChannels.length > 0" class="talks-list" :style="{height: pageHeight}" style="padding: 10px 0 !important; overflow-y: auto;">
        <v-list dense two-line style="padding: 0;">
          <template v-for="(channel, channelKey) in filteredChannels">
            <v-divider v-if="channelKey" :key="channelKey"></v-divider>
            <v-list-tile
              v-ripple avatar :key="channelKey + '-new'"
              @click="channelAction(channel)"
              class="talks-channel-list-item"
            >
              <v-list-tile-avatar style="cursor: pointer;">
                <div class="d-flex flex-column align-center" style="flex-direction: column;">
                  <img v-if="channel.type.code == 'wpp'" style="max-width: 30px;" src="static/icons/monitor/whatsapp.png"/>
                  <img v-if="channel.type.code == 'sms'" style="max-width: 30px;" src="static/icons/monitor/sms.png"/>
                  <img v-if="channel.type.code == 'instagram'" style="max-width: 30px;" src="static/icons/monitor/instagram.png"/>
                  <img v-if="channel.type.code == 'facebook'" style="max-width: 30px;" src="static/icons/monitor/facebook.png"/>
                  <img v-if="channel.type.code == 'email'" style="max-width: 30px;" src="static/icons/monitor/email.png"/>
                  <span class="status-text" :style="{ color: channel.type.color }">
                    {{ channel.type.name | stringlength(10) }}
                  </span>
                </div>
              </v-list-tile-avatar>
              <v-list-tile-content style="cursor: pointer;">
                <v-list-tile-title>
                  <span 
                    class="channel-name" 
                    :style="{ 
                      backgroundColor: channel.color,
                      color: $WiColorContrast(channel.color),
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: $WiColorContrast(channel.color)
                    }"
                  >
                    {{ channel.name }}
                  </span>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <div style="display: flex; gap: 1px; justify-content: start; align-items: center;">
                    <span v-if="channel.recipient" style="margin-top:2px; margin-left: 2px; color: grey; font-size: 12px;">
                      {{ channel.recipient }}
                    </span>
                    <span v-else style="margin-top:2px; margin-left: 2px; color: grey; font-size: 12px;">- - -</span>
                  </div>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-avatar style="cursor: pointer; margin-right: 10px;">
                <div class="d-flex flex-column align-center" style="flex-direction: column;">
                  <v-icon :color="channel.status.color" size="28">
                    {{ channel.status.icon }}
                  </v-icon>
                  <span class="status-text" :style="{ color: channel.status.color }">
                    {{ channel.status.name | stringlength(10) }}
                  </span>
                </div>
              </v-list-tile-avatar>
              <v-list-tile-action style="text-align: center; min-width: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <strong style="font-size: 18px;">
                  {{ channel.talks_opened }}
                </strong>
                <v-icon style="font-size: 21px;">message</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list>
      </div>
      <div v-else class="talks-list__not-message">
        <div class="talks-list__not-message-content">
          <img
            src="static/background/whatsapp-not-found.png?url=temp1"
            style="max-width: 150px;" alt=""
          />
          <div class="talks-list__not-message-text">
            {{ searchTerm ? 'Nenhum canal encontrado para esta busca' : $t('modules.talks.pages.talkView.talks.messages.notFound.channels') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import TalksList from '@/modules/talk/pages/TalkView/Talks/TalksList'

export default {
  name: 'TalksChannels',
  data: () => ({
    pageHeight: innerHeight,
    talkDialog: false,
    currentTalkId: null
  }),
  computed: {
    ...mapState('Talks', ['selectedChannel', 'loadings', 'channels']),
    filteredChannels() {
      if (!this.searchTerm) return this.channels

      const searchLower = this.searchTerm.toLowerCase()
      return this.channels.filter(channel => {
        const nameMatch = channel.name.toLowerCase().includes(searchLower)
        const recipientMatch = channel.recipient ? 
          channel.recipient.toString().toLowerCase().includes(searchLower) : 
          false
        
        return nameMatch || recipientMatch
      })
    }
  },
  methods: {
    ...mapMutations('Talks', ['SELECTED_CHANNEL']),
    ...mapActions('Talks', ['paginateTalks', 'getChannels']),
    setInnerHeight: function (heigth) {
      this.pageHeight = ((heigth || innerHeight) - 145) + 'px'
    },
    updateView: function () {
      setInterval(() => {
        this.$forceUpdate()
      }, 78000)
    },
    channelAction: function (channel) {
      this.SELECTED_CHANNEL(channel)
      this.paginateTalks()
    }
  },
  mounted: function () {
    this.getChannels()
    window.addEventListener('resize', () => {
      this.setInnerHeight(innerHeight)
    }, true)
    this.setInnerHeight(innerHeight)
    this.updateView()
  },
  props: {
    searchTerm: {
      type: String,
      default: ''
    }
  },
  components: {
    TalksList
  }
}
</script>

<style scoped>
.talks-list .v-list__tile {
  padding: 0 5px 0 5px !important;
}
.talks-list .v-list__tile__avatar {
  /* min-width: 70px !important; */
}
.talks-list__not-message {
  align-items: center;
}
.talks-list__not-message-content {
  padding: 50px 40px;
  text-align: -webkit-center;
  font-size: 20px;
}
.talks-list__not-message img {
  width: 100%;
}
.talks-list__not-message-text {
  padding-bottom: 20px;
}
.status-text {
  font-size: 9px;
  line-height: 1;
  margin-top: 2px;
  white-space: nowrap;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-list__tile__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px !important;
}
.talks-channel-list-item >>> .v-list__tile {
  padding: 0 5px !important;
}
.channel-name {
  display: inline-block;
  padding: 1px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0073529412em;
  white-space: nowrap;
  text-transform: none;
  margin-bottom: 5px;
}

.search-feedback {
  padding: 8px 16px;
  color: white;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  font-weight: 400;
  opacity: 0.9;
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgb(179 179 179 / 35%);
  border-radius: 4px;
  margin: 8px 16px;
}

.search-term {
  color: #2196F3;
  font-weight: 500;
  background: rgba(33, 150, 243, 0.1);
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

/* Ajuste para quando houver busca ativa */
.talks-list {
  height: calc(v-bind(pageHeight) - 60px) !important;
}
</style> 