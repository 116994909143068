import languages from './languages.json'
import countries from './countries-v2.json'
import { Model } from '@/default/model/Model'

const countriesList = countries.map((country) => {
  return {
    value: country,
    text: country.name + ' (' + country.iso + ')'
  }
})
// const countriesList = []

// for (let country in countries) {
//   countriesList.push({
//     value: country,
//     text: country
//   })
// }

// console.log(countriesList)

export class TalkContact extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: '9f88b04d',
      create: '1c1a02d4',
      update: '8fe1cf2b',
      delete: '139127e8'
    }
    this.name = 'talk-contact'
    this.title = {
      list: 'Contatos',
      edit: {
        insert: 'Criar novo Contato',
        update: 'Editar Contato'
      }
    }
    this.apiUrl = 'talk/contact'
    this.icon = 'person'
    this.maxWidth = '800px'
    this.params = {
      limit: 10,
      order: 'last_event_at,desk',
      where: 'type,1',
      with: 'opened_talks:id,talk_contact_id'
    }
    this.search = {
      placeholder: 'Pesquise pelo nome, número ou documento do contato',
      fields: ['name', 'number', 'contact', 'person.name', 'person.first_name', 'person.cpf_cnpj']
    }
    // this.fieldsSelected = {
    //   itemName: 'contatos(s)'
    // }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl12']
    this.components.list.tableRow = () => import('@/modules/talk/pages/Talks/TalksContacts')
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe o nome e número de WhatsApp deste contato.'
      }),
      this.fieldSelectApi({
        value: 'talk_channel_id',
        text: 'Canal de atendimento',
        required: true,
        size: form => {
          if (form.id) {
            return ['xs12', 'sm12', 'md5', 'lg5']
          }
          return ['xs12', 'sm12', 'md3', 'lg3']
        },
        api: {
          url: 'talk/channel',
          params: {
            fields: 'id,uuid,name,type_id,status_id',
          }
        },
        show: 'name_complete',
        // disabled: form => (form.id != null)
      }),
      this.fieldText({
        value: 'name',
        text: 'Nome',
        placeholder: 'Ex: Pedro Henrique',
        required: true,
        size: form => {
          if (form.id) {
            return ['xs12', 'sm12', 'md7', 'lg7']
          }
          return ['xs12', 'sm12', 'md4', 'lg4']
        }
      }),
      this.fieldSelect({
        value: 'number_type',
        text: 'País',
        options: countriesList,
        autocomplete: true,
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        showField: form => (!form.id)
      }),
      this.fieldText({
        value: 'number',
        text: 'Número',
        placeholder: form => (form.number_type ? form.number_type?.code + ' ' + (typeof form.number_type?.mask == 'string' ? form.number_type?.mask : form.number_type?.mask[0]) : '## (##) # ####-####'),
        required: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        mask: form => (form.number_type ? form.number_type?.code + ' ' + (typeof form.number_type?.mask == 'string' ? form.number_type?.mask : form.number_type?.mask[0]) : '## (##) # ####-####'),
        showField: form => (!form.id)
      }),
      this.fieldSubHeader({
        text: 'Defina o perfil deste contato e se deseja ativar tradução simultânea.'
      }),
      this.fieldSelect({
        value: 'person_type',
        text: 'Tipo de Perfil',
        options: [
          {value: 'contact', text: 'Contato de um cliente ou fornecedor'},
          {value: 'customer', text: 'Cliente'},
          {value: 'collaborator', text: 'Colaborador'},
          {value: 'provider', text: 'Fornecedor'},
          // {value: 'prospect', text: 'Prospect'}
        ],
        required: false,
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Vincule este contato a um Cliente',
        required: false,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: form => (form.person_type == 'customer')
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Vincule este contato a um Colaborador',
        required: false,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'collaborator',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: form => (form.person_type == 'collaborator')
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Vincule este contato a um Contato',
        required: false,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'contact',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: form => (form.person_type == 'contact' || !form.person_type)
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Vincule este contato a um Fornecedor',
        required: false,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'provider',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: form => (form.person_type == 'provider')
      }),
      this.fieldWiListDialog({
        value: 'person_id',
        text: 'Vincule este contato a um Prospect',
        required: false,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        wiConfig: 'prospect',
        show: 'person.first_name',
        icon: 'person_outline',
        showField: form => (form.person_type == 'prospect')
      }),
      this.fieldSwitch({
        value: 'set_locale',
        text: 'Tradução simultânea',
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: (form) => { return !form.set_locale && !form.locale }
      }),
      this.fieldSelect({
        value: 'locale',
        text: 'Idioma (Tradução simultânea)',
        placeholder: 'Selecione o idioma',
        options: languages,
        autocomplete: true,
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        showField: form => (form.set_locale || form.locale)
      }),
      this.fieldTextArea({
        value: 'observation',
        text: 'Observações do contato',
        placeholder: 'Use este campo para observações'
      }),
      this.fieldList({
        value: 'name',
        text: 'Contato'
      }),
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'person_id',
        text: 'Perfil'
      }),
      this.fieldList({
        value: 'id',
        text: 'Opções'
      })
    ])
  }
}

export class TalkContactGroup extends TalkContact {
  constructor () {
    super()
    this.actions = {
      list: true,
      update: true,
      delete: true
    }
    this.title = {
      list: 'Grupos',
      edit: 'Editar Grupo'
    }
    this.icon = 'person'
    this.params.where = 'type,2'

    this.fields[6].text = 'Vincule este grupo a um Cliente'
    this.fields[7].text = 'Vincule este grupo a um Colaborador'
    this.fields[9].text = 'Vincule este grupo a um Fornecedor'
    this.fields[5] = this.fieldSelect({
      value: 'person_type',
      text: 'Tipo de Perfil',
      options: [
        {value: 'customer', text: 'Cliente'},
        {value: 'collaborator', text: 'Colaborador'},
        {value: 'provider', text: 'Fornecedor'},
        {value: 'team', text: 'Equipe/Departamento'},
      ],
      required: true,
      size: form => (form.id ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md4', 'lg4'])
    })
    this.fields[8] = this.fieldWiListDialog({
      value: 'team_id',
      text: 'Vincular a uma Equipe/Departamento',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      wiConfig: 'team',
      show: 'team.name',
      icon: 'person',
      showField: form => (form.person_type == 'team')
    })

    // this.fields[2].size = ['xs12', 'sm12', 'md9', 'ls9']
    // this.fields = this.fields.filter((field, fieldIndex) => (fieldIndex < 3 || fieldIndex > 9))
    // this.fields.splice(7, 1)
  }
}

export class TalkContactAll extends TalkContact {
  constructor () {
    super()
    this.actions = {
      list: true,
      update: false,
      delete: false
    }
    this.title = {
      list: 'Contatos e Grupos'
    }
    this.search.placeholder = 'Pesquise pelo Nome ou Número'
    this.icon = 'person'
    this.maxWidth = '800px'
    delete this.params.where
    this.params['limit'] = 10
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkContacts/TalkContacts')
    this.fields = [
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'name',
        text: 'Nome do Contato'
      }),
      this.fieldList({
        value: 'last_event_at',
        text: 'Interação'
      })
    ]
  }
}

export class TalkContactAllMultiple extends TalkContactAll {
  constructor () {
    super()
    this.fieldsSelected = {
      itemName: ' contato(s)'
    }
  }
}
