<template>
  <div>
    <!-- Loading State -->
    <TalksListLoading 
      v-if="loadings.load"
      :type="type"
    />
    
    <div v-else>
      <!-- Talks List -->
      <div
        v-if="talks.length > 0"
        class="talks-list"
        :style="{height: pageHeight}"
        style="padding: 10px 0 !important; overflow-y: auto;"
      >
        <v-list dense three-line style="padding: 0;">
          <TalkListItem
            v-for="(talk, index) in talks"
            :key="talk.id"
            :talk="talk"
            :index="index"
            :currentRouteId="$route.params.id"
            :onRefresh="getTalks"
            @onTalkAction="talkAction"
          />
        </v-list>
        
        <LoadMoreButton 
          @click="paginateTalks(type)"
          :loading="loadings.paginate"
        />
      </div>

      <!-- Empty State -->
      <TalksListEmpty 
        v-else
        :type="type"
      />
    </div>

    <!-- Talk Dialog -->
    <TalkDialog
      v-model="talkDialog"
      :talkId="currentTalkId"
      @close="onCloseTalkDialog"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TalkListItem from '@/modules/talk/components/TalkListItem'
import TalksListLoading from '@/modules/talk/components/TalksListLoading'
import TalksListEmpty from '@/modules/talk/components/TalksListEmpty'
import TalkDialog from '@/modules/talk/components/TalkDialog'
import LoadMoreButton from '@/modules/talk/components/LoadMoreButton'

export default {
  name: 'TalksList',
  
  components: {
    TalkListItem,
    TalksListLoading,
    TalksListEmpty,
    TalkDialog,
    LoadMoreButton
  },

  props: ['type'],

  data: () => ({
    pageHeight: innerHeight,
    talkDialog: false,
    currentTalkId: null
  }),

  computed: {
    ...mapState('Talks', ['loadings']),
    ...mapGetters('Talks', ['myTalks', 'queue', 'others', 'groups', 'byOperator', 'byChannel', 'historyTalks']),
    
    talks() {
      const talkTypes = {
        'my-talks': this.myTalks,
        'queue': this.queue,
        'others': this.others,
        'groups': this.groups,
        'by-operator': this.byOperator,
        'by-channel': this.byChannel,
        'history': this.historyTalks
      }
      return talkTypes[this.type] || []
    }

  },

  methods: {
    ...mapActions('Talks', ['paginateTalks', 'getTalks']),
    
    talkAction(talk) {
      const isInTalksRoute = ['talks', 'talk-messages', 'talk', 'talk-view', 'talk-view-messages', 'talk-view-messages-page'].includes(this.$route.name)
      
      if (isInTalksRoute) {
        this.openTalkPage(talk)
      } else {
        this.openTalkDialog(talk)
      }
    },

    openTalkPage(talk) {
      const routeName = this.$vuetify.breakpoint.smAndDown ? 'talk-view-messages-page' : 'talk-view-messages'
      
      this.$router.push({
        name: routeName,
        params: { id: talk.id },
        query: { previousTalkId: this.$route.params.id }
      })
    },

    openTalkDialog(talk) {
      this.currentTalkId = talk.id
      this.talkDialog = true
    },

    onCloseTalkDialog() {
      this.talkDialog = false
      this.currentTalkId = null
    },

    setInnerHeight(height) {
      this.pageHeight = `${(height || innerHeight) - 145}px`
    },

    initializeView() {
      window.addEventListener('resize', () => this.setInnerHeight(innerHeight))
      this.setInnerHeight(innerHeight)
      setInterval(() => this.$forceUpdate(), 78000)
    }
  },

  mounted() {
    this.initializeView()
  }
}
</script>