import { Filters } from '@/default/model/Filters'

export class FileFilters extends Filters {
  constructor (title = 'Filtrar Arquivos') {
    super()

    this.title = title
    this.maxWidth = '480px'
    this.fields = {
      extension: this.fieldSelect({
        value: 'extension',
        text: 'Extensão',
        options: [
          // images
          { value: 'jpg', text: 'JPG' },
          { value: 'jpeg', text: 'JPEG' },
          { value: 'png', text: 'PNG' },
          { value: 'gif', text: 'GIF' },
          { value: 'bmp', text: 'BMP' },
          { value: 'tiff', text: 'TIFF' },
          // documents  
          { value: 'pdf', text: 'PDF' },
          { value: 'doc', text: 'DOC' },
          { value: 'docx', text: 'DOCX' },
          { value: 'xls', text: 'XLS' },
          { value: 'xlsx', text: 'XLSX' },
          { value: 'ppt', text: 'PPT' },
          { value: 'pptx', text: 'PPTX' },
          { value: 'txt', text: 'TXT' },
          { value: 'csv', text: 'CSV' },
          { value: 'zip', text: 'ZIP' },
          { value: 'rar', text: 'RAR' },
          // audio
          { value: 'mp3', text: 'MP3' },
          { value: 'wav', text: 'WAV' },
          { value: 'm4a', text: 'M4A' },
          { value: 'ogg', text: 'OGG' },
          { value: 'flac', text: 'FLAC' },
          { value: 'aac', text: 'AAC' },
          { value: 'wma', text: 'WMA' },
          { value: 'm4b', text: 'M4B' },
          { value: 'm4p', text: 'M4P' },
          { value: 'm4b', text: 'M4B' },
          // video
          { value: 'mp4', text: 'MP4' },
          { value: 'avi', text: 'AVI' },
          { value: 'mov', text: 'MOV' },
          { value: 'wmv', text: 'WMV' },
          { value: 'flv', text: 'FLV' },
          { value: 'm4v', text: 'M4V' },
          { value: 'webm', text: 'WEBM' },
          { value: 'mkv', text: 'MKV' },
          { value: 'm4b', text: 'M4B' },
          { value: 'm4p', text: 'M4P' },
          { value: 'm4b', text: 'M4B' },
          // other
          { value: 'other', text: 'Outro' }
        ],
      }),
      date_start: this.fieldDate({
        value: 'date_start',
        text: 'Data Inicial',
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      date_end: this.fieldDate({
        value: 'date_end',
        text: 'Data Final',
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
    }
  }
}
