<template>
  <WiCategories
    title="Categorias de Despesas"
    viewIndex="finance-categories"
    apiEndpoint="finance/category/hierarchy/out"
    deleteEndpoint="finance/category"
    editConfig="finance-category"
    parentCategoryField="finance_category_id"
    colorField="color"
    iconField="icon"
    @on-edit-category="onEditCategory"
    @on-add-category="onAddCategory"
    @on-add-child-category="onAddChildCategory"
  />
</template>

<script>
import WiCategories from '@/default/component/WiCategories'

export default {
  name: 'finance-categories',
  components: {
    WiCategories
  },
  methods: {
    onEditCategory({ refresh, category }) {
      this.$WiEditDialog({
        wiConfig: 'finance-out-categories',
        data: category,
        redirectOnSubmit: false,
        onSubmit: refresh
      })
    },
    onAddCategory({ refresh }) {
      this.$WiEditDialog({
        wiConfig: 'finance-out-categories',
        data: {},
        redirectOnSubmit: false,
        onSubmit: refresh
      })
    },
    onAddChildCategory({ refresh, parentCategory }) {
      this.$WiEditDialog({
        wiConfig: 'finance-out-categories',
        data: {
          finance_category_id: parentCategory.id,
          color: parentCategory.color,
          icon: parentCategory.icon
        },
        redirectOnSubmit: false,
        onSubmit: refresh
      })
    }
  }
}
</script>