<template>
  <WiView
    v-if="apiParams"
    index="order-dashboard"
    :apiUrl="'service-desk/order/dashboard'"
    :apiParams="apiParams"
    :notShowToolbar="true"
    :notGetID="true"
    @afterLoad="setContent"
  >
    <template slot-scope="{ content, refresh }">
      <v-container style="padding: 10px;" fluid grid-list-md>
        <v-layout row wrap>
          <!-- Header -->
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-btn icon ripple @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-list-tile v-ripple avatar class="hidden-xs-only hidden-sm-only">
                <v-list-tile-avatar @click="refresh()" style="cursor: pointer;" size="45">
                  <img src="static/systems/modules/wises-technology-module-service-desk.png" alt="Dashboard Ordens de Serviço">
                </v-list-tile-avatar>
                <v-list-tile-content @click="refresh()" style="cursor: pointer;">
                  <v-list-tile-sub-title style="font-size: 12px;">
                    <span v-t="'modules.serviceDesk.pages.orderDashboard.header.module'"></span>
                  </v-list-tile-sub-title>
                  <v-list-tile-title style="font-size:18px;">
                    <span v-t="'modules.serviceDesk.pages.orderDashboard.header.title'"></span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-spacer></v-spacer>
              
              <!-- Filtro de Data -->
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outline small>
                    <v-icon left>event</v-icon>
                    <span class="pr-2 hidden-xs-only hidden-sm-only" v-t="'modules.serviceDesk.pages.orderDashboard.header.dateFilter.startDate'"></span>
                    <strong class="pr-2">{{ dateStart | moment('DD/MM/YY') }}</strong>
                    <span class="pr-2 hidden-xs-only hidden-sm-only" v-t="'modules.serviceDesk.pages.orderDashboard.header.dateFilter.endDate'"></span>
                    <strong>{{ dateEnd | moment('DD/MM/YY') }}</strong>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title v-t="'modules.serviceDesk.pages.orderDashboard.header.dateFilter.dialog.title'"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-text-field
                      dense
                      outline
                      v-model="dateStart"
                      type="date"
                      label="Data Inicial"
                    ></v-text-field>
                    <v-text-field
                      dense
                      outline
                      v-model="dateEnd"
                      type="date"
                      label="Data Final"
                    ></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn block outline color="green" @click="changeFilters()">
                      <v-icon>filter_list</v-icon>
                      <span>Filtrar</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          
        </v-layout>

          <!-- Cards Estatísticos -->
          <v-layout row wrap>
            <v-flex xs12 sm6 md3>
              <DashboardCard
                :title="$t('modules.serviceDesk.pages.orderDashboard.statistics.cards.totalClosed.title')"
                icon="check_circle"
                color="blue"
                :data="content.statistics.count_total_closed"
              />
            </v-flex>
            <v-flex xs12 sm6 md3>
              <DashboardCard
                :title="$t('modules.serviceDesk.pages.orderDashboard.statistics.cards.amountClosed.title')"
                icon=""
                color="green"
                :data="content.statistics.amount_total_closed | money"
              />
            </v-flex>
            <v-flex xs12 sm6 md3>
              <DashboardCard
                :title="$t('modules.serviceDesk.pages.orderDashboard.statistics.cards.totalOpened.title')"
                icon="pending_actions"
                color="#FFC107"
                :data="content.statistics.count_total_opened"
              />
            </v-flex>
            <v-flex xs12 sm6 md3>
              <DashboardCard
                :title="$t('modules.serviceDesk.pages.orderDashboard.statistics.cards.totalOpened.title')"
                icon=""
                color="#FF9800"
                :data="content.statistics.amount_total_opened | money"
              />
            </v-flex>
          </v-layout>

          <!-- Substituir o antigo card pelo novo componente -->
          <OrdersByStageChart
            v-if="content.statistics"
            :data="content.statistics.orders_by_stage"
          />
          <!-- Add after OrdersByStageChart -->
          <OrdersYearlyComparisonChart
            v-if="content.statistics && content.statistics.orders_by_stage_yearly"
            :data="content.statistics.orders_by_stage_yearly"
          />
          <OrdersAmountYearlyComparisonChart
            v-if="content.statistics && content.statistics.orders_amount_by_stage_yearly"
            :data="content.statistics.orders_amount_by_stage_yearly"
          />

          <!-- Add after OrdersByStageChart -->
          <OrdersAmountByStageChart
            v-if="content.statistics"
            :data="content.statistics.orders_amount_by_stage"
          />

          <!-- Novo componente de Pódio -->
          <!-- <v-layout row wrap>
            <v-flex v-if="
                content &&
                content.statistics &&
                content.statistics.amount_closed_by_collaborator &&
                content.statistics.amount_closed_by_collaborator.length > 0
              " xs12 md6 lg6 xl6 class="mt-4">
              <v-card class="elevation-10" style="border-radius: 10px;">
                <div style="padding: 10px; display: flex; align-items: center;">
                    <v-icon>person</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.podium.title'"></h4>
                  </div>
                <v-card-text>
                  <RankingPodium 
                    :collaborators="content.statistics.amount_closed_by_collaborator"
                  />
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex v-if="
                content &&
                content.statistics &&
                content.statistics.amount_closed_by_collaborator &&
                content.statistics.amount_closed_by_collaborator.length > 0
              " xs12 md6 lg6 xl6 class="mt-4">
              <v-card class="elevation-10" style="border-radius: 10px;">
                <div style="padding: 10px; display: flex; align-items: center;">
                    <v-icon>person</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.barChart.title'"></h4>
                  </div>
                <v-card-text style="padding: 2px 15px;">
                  <PeriodColumnChart
                    :collaborators="content.statistics.amount_closed_by_collaborator"
                  ></PeriodColumnChart>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout> -->
          <!-- Tabela de Colaboradores -->
          <v-card
            v-if="
              content &&
              content.statistics &&
              content.statistics.amount_closed_by_collaborator &&
              content.statistics.amount_closed_by_collaborator.length > 0
            "
            class="elevation-10 mt-4" style="border-radius: 10px;"
          >
            <v-layout row wrap>
              <v-flex xs12 md6 lg6 xl6 class="pr-md-2">
                
                  <div style="padding: 10px; display: flex; align-items: center;">
                    <v-icon>person</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.collaborators.title.closed'"></h4>
                  </div>
                  <v-data-table
                    v-if="
                      content &&
                      content.statistics &&
                      content.statistics.amount_closed_by_collaborator &&
                      content.statistics.amount_closed_by_collaborator.length > 0
                    "
                    dense
                    :headers="headersCollaborators"
                    :items="content.statistics.amount_closed_by_collaborator"
                    class="elevation-0"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-initial-sort
                    hide-actions
                  >
                    <template v-slot:items="props">
                      <td>
                        <v-list dense  color="transparent" style="padding: 0 !important; margin: 0 !important;">
                        <v-list-tile avatar color="transparent">
                          <v-list-tile-avatar v-if="props.item.file">
                            <img :src="props.item.file.url" :alt="props.item.name">
                          </v-list-tile-avatar>
                          <v-list-tile-avatar v-else>
                            <v-icon>person</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              <strong>
                                {{ props.item.name }}
                              </strong>
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      </td>
                      <td class="text-xs-right">
                        <strong style="font-size: 18px;">
                          {{ props.item.count }}
                        </strong>
                      </td>
                      <td class="text-xs-right">
                        <strong style="font-size: 15px;">
                          {{ props.item.amount | money }}
                        </strong>
                      </td>
                    </template>
                  </v-data-table>
                
              </v-flex>
              <v-flex xs12 md6 lg6 xl6 class="pr-md-2">
                
                  <v-card-text>
                    <div style="display: flex; align-items: center;">
                      <v-icon>pie_chart</v-icon>
                      <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.collaborators.distribution.closed'"></h4>
                    </div>
                    <div>
                      <apexchart
                        width="90%"
                        type="donut"
                        :series="setSeriesAmountByCollaborator(
                          content.statistics.amount_closed_by_collaborator
                        )"
                        :options="optionsCollaborators"
                      ></apexchart>
                    </div>
                  </v-card-text>
                
              </v-flex>
            </v-layout>
          </v-card>

          <!-- Adicionar após as tabelas -->
          <v-card
            v-if="
              content &&
              content.statistics &&
              content.statistics.amount_closed_by_collaborator &&
              content.statistics.amount_closed_by_collaborator.length > 0
            "
            class="elevation-10 mt-4" style="border-radius: 10px;"
          >
            <v-layout row wrap v-if="content && content.statistics">
              <v-flex xs12 md6 lg6 xl6 class="pl-md-2">
                <div style="padding: 10px; display: flex; align-items: center;">
                  <v-icon>category</v-icon>
                  <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.categories.title.closed'"></h4>
                </div>
                <v-data-table
                  v-if="
                    content &&
                    content.statistics &&
                    content.statistics.amount_closed_by_category &&
                    content.statistics.amount_closed_by_category.length > 0
                  "
                  :headers="headersCategories"
                  :items="content.statistics.amount_closed_by_category"
                  class="elevation-0"
                  hide-default-footer
                  :items-per-page="-1"
                  disable-initial-sort
                  hide-actions
                >
                  <template v-slot:items="props">
                    <td>
                      <v-list dense  color="transparent" style="padding: 0 !important; margin: 0 !important;">
                      <v-list-tile avatar>
                        <v-list-tile-avatar v-if="props.item.file">
                          <img :src="props.item.file.url" :alt="props.item.name">
                        </v-list-tile-avatar>
                        <v-list-tile-avatar v-else :color="props.item.color">
                          <v-icon color="white">{{ props.item.icon }}</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            <strong v-if="props.item.file" style="color: white; padding: 0 5px; border-radius: 5px;" :style="{ backgroundColor: props.item.color }">
                              {{ props.item.name }}
                            </strong>
                            <strong v-else>
                              {{ props.item.name }}
                            </strong>
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 18px;">
                        {{ props.item.count }}
                      </strong>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 15px;">
                        {{ props.item.amount | money }}
                      </strong>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12 md6 lg6 xl6 class="pl-md-2">
                <v-card-text>
                  <div style="display: flex; align-items: center;">
                    <v-icon>pie_chart</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.categories.distribution.closed'"></h4>
                  </div>
                  <div v-if="
                    content &&
                    content.statistics &&
                    content.statistics.amount_closed_by_category &&
                    content.statistics.amount_closed_by_category.length > 0
                  ">
                    <apexchart
                      width="100%"
                      type="donut"
                      :series="setSeriesAmountByCategory(
                        content.statistics.amount_closed_by_category
                      )"
                      :options="optionsCategories"
                    ></apexchart>
                  </div>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>

          <!-- asdasdasdasdasdasdasdasdsd -->

          <v-card
            v-if="
              content &&
              content.statistics &&
              content.statistics.amount_opened_by_collaborator &&
              content.statistics.amount_opened_by_collaborator.length > 0
            "
            class="elevation-10 mt-4" style="border-radius: 10px;"
          >
            <v-layout row wrap>
              <v-flex xs12 md6 lg6 xl6 class="pr-md-2">
                
                  <div style="padding: 10px; display: flex; align-items: center;">
                    <v-icon>person</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.collaborators.title.opened'"></h4>
                  </div>
                  <v-data-table
                    v-if="
                      content &&
                      content.statistics &&
                      content.statistics.amount_opened_by_collaborator &&
                      content.statistics.amount_opened_by_collaborator.length > 0
                    "
                    dense
                    :headers="headersCollaborators"
                    :items="content.statistics.amount_opened_by_collaborator"
                    class="elevation-0"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-initial-sort
                    hide-actions
                  >
                    <template v-slot:items="props">
                      <td>
                        <v-list dense  color="transparent" style="padding: 0 !important; margin: 0 !important;">
                        <v-list-tile avatar color="transparent">
                          <v-list-tile-avatar v-if="props.item.file">
                            <img :src="props.item.file.url" :alt="props.item.name">
                          </v-list-tile-avatar>
                          <v-list-tile-avatar v-else>
                            <v-icon>person</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              <strong>
                                {{ props.item.name }}
                              </strong>
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      </td>
                      <td class="text-xs-right">
                        <strong style="font-size: 18px;">
                          {{ props.item.count }}
                        </strong>
                      </td>
                      <td class="text-xs-right">
                        <strong style="font-size: 15px;">
                          {{ props.item.amount | money }}
                        </strong>
                      </td>
                    </template>
                  </v-data-table>
                
              </v-flex>
              <v-flex xs12 md6 lg6 xl6 class="pr-md-2">
                
                  <v-card-text>
                    <div style="display: flex; align-items: center;">
                      <v-icon>pie_chart</v-icon>
                      <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.collaborators.distribution.opened'"></h4>
                    </div>
                    <div>
                      <apexchart
                        width="90%"
                        type="donut"
                        :series="setSeriesAmountByCollaborator(
                          content.statistics.amount_opened_by_collaborator
                        )"
                        :options="optionsCollaborators"
                      ></apexchart>
                    </div>
                  </v-card-text>
                
              </v-flex>
            </v-layout>
          </v-card>

          <!-- Adicionar após as tabelas -->
          <v-card
            v-if="
              content &&
              content.statistics &&
              content.statistics.amount_opened_by_collaborator &&
              content.statistics.amount_opened_by_collaborator.length > 0
            "
            class="elevation-10 mt-4" style="border-radius: 10px;"
          >
            <v-layout row wrap v-if="content && content.statistics">
              <v-flex xs12 md6 lg6 xl6 class="pl-md-2">
                <div style="padding: 10px; display: flex; align-items: center;">
                  <v-icon>category</v-icon>
                  <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.categories.title.opened'"></h4>
                </div>
                <v-data-table
                  v-if="
                    content &&
                    content.statistics &&
                    content.statistics.amount_opened_by_category &&
                    content.statistics.amount_opened_by_category.length > 0
                  "
                  :headers="headersCategories"
                  :items="content.statistics.amount_opened_by_category"
                  class="elevation-0"
                  hide-default-footer
                  :items-per-page="-1"
                  disable-initial-sort
                  hide-actions
                >
                  <template v-slot:items="props">
                    <td>
                      <v-list dense  color="transparent" style="padding: 0 !important; margin: 0 !important;">
                      <v-list-tile avatar>
                        <v-list-tile-avatar v-if="props.item.file">
                          <img :src="props.item.file.url" :alt="props.item.name">
                        </v-list-tile-avatar>
                        <v-list-tile-avatar v-else :color="props.item.color">
                          <v-icon color="white">{{ props.item.icon }}</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            <strong v-if="props.item.file" style="color: white; padding: 0 5px; border-radius: 5px;" :style="{ backgroundColor: props.item.color }">
                              {{ props.item.name }}
                            </strong>
                            <strong v-else>
                              {{ props.item.name }}
                            </strong>
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 18px;">
                        {{ props.item.count }}
                      </strong>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 15px;">
                        {{ props.item.amount | money }}
                      </strong>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12 md6 lg6 xl6 class="pl-md-2">
                <v-card-text>
                  <div style="display: flex; align-items: center;">
                    <v-icon>category</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.categories.distribution.opened'"></h4>
                  </div>
                  <div v-if="
                    content &&
                    content.statistics &&
                    content.statistics.amount_opened_by_category &&
                    content.statistics.amount_opened_by_category.length > 0
                  ">
                    <apexchart
                      width="100%"
                      type="donut"
                      :series="setSeriesAmountByCategory(
                        content.statistics.amount_opened_by_category
                      )"
                      :options="optionsCategories"
                    ></apexchart>
                  </div>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
          
          <v-card
            v-if="
              !content ||
              !content.statistics ||
              !content.statistics.amount_closed_by_collaborator ||
              content.statistics.amount_closed_by_collaborator.length < 1 ||
              !content.statistics.amount_opened_by_collaborator ||
              content.statistics.amount_opened_by_collaborator.length < 1 
            "
            class="elevation-10 mt-4" style="border-radius: 10px;"
          >

            <v-card-text style="display: flex; flex-direction: column; gap: 30px; padding: 30px; text-align: center; align-items: center;">
              <v-img src="/static/icons/monitor/evidence_96.png" width="100px"></v-img>
              <h3 v-t="'modules.serviceDesk.pages.orderDashboard.noData.title'"></h3>
              <h2>
                <span>{{ dateStart | moment('DD/MM/YYYY') }}</span>
                <span> até </span>
                <span>{{ dateEnd | moment('DD/MM/YYYY') }}</span>
              </h2>
              <span v-t="'modules.serviceDesk.pages.orderDashboard.noData.description'"></span>
              <v-btn outline color="primary" @click="dialog = true">
                <v-icon left>event</v-icon>
                <span v-t="'modules.serviceDesk.pages.orderDashboard.noData.button'"></span>
              </v-btn>
            </v-card-text>
          </v-card>

          <!-- Item Categories - Closed -->
          <v-card
            v-if="
              content &&
              content.statistics &&
              content.statistics.amount_closed_by_item_category &&
              content.statistics.amount_closed_by_item_category.length > 0
            "
            class="elevation-10 mt-4" 
            style="border-radius: 10px;"
          >
            <v-layout row wrap>
              <v-flex xs12 md6 lg6 xl6 class="pr-md-2">
                <div style="padding: 10px; display: flex; align-items: center;">
                  <v-icon>inventory_2</v-icon>
                  <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.itemCategories.title.closed'"></h4>
                </div>
                <v-data-table
                  dense
                  :headers="headersItemCategories"
                  :items="content.statistics.amount_closed_by_item_category"
                  class="elevation-0"
                  hide-default-footer
                  :items-per-page="-1"
                  disable-initial-sort
                  hide-actions
                >
                  <template v-slot:items="props">
                    <td>
                      <v-list dense color="transparent" style="padding: 0 !important; margin: 0 !important;">
                        <v-list-tile avatar>
                          <v-list-tile-avatar v-if="props.item.file">
                            <img :src="props.item.file.url" :alt="props.item.name">
                          </v-list-tile-avatar>
                          <v-list-tile-avatar v-else :color="props.item.color">
                            <v-icon color="white">{{ props.item.icon }}</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              <strong>{{ props.item.name }}</strong>
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 18px;">{{ props.item.count }}</strong>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 15px;">{{ props.item.amount | money }}</strong>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12 md6 lg6 xl6 class="pl-md-2">
                <v-card-text>
                  <div style="display: flex; align-items: center;">
                    <v-icon>pie_chart</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.itemCategories.distribution.closed'"></h4>
                  </div>
                  <div>
                    <apexchart
                      width="100%"
                      type="donut"
                      :series="setSeriesAmountByItemCategory(content.statistics.amount_closed_by_item_category)"
                      :options="optionsItemCategories"
                    ></apexchart>
                  </div>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>

          <!-- Item Categories - Opened -->
          <v-card
            v-if="
              content &&
              content.statistics &&
              content.statistics.amount_opened_by_item_category &&
              content.statistics.amount_opened_by_item_category.length > 0
            "
            class="elevation-10 mt-4" 
            style="border-radius: 10px;"
          >
            <v-layout row wrap>
              <v-flex xs12 md6 lg6 xl6 class="pr-md-2">
                <div style="padding: 10px; display: flex; align-items: center;">
                  <v-icon>inventory_2</v-icon>
                  <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.itemCategories.title.opened'"></h4>
                </div>
                <v-data-table
                  dense
                  :headers="headersItemCategories"
                  :items="content.statistics.amount_opened_by_item_category"
                  class="elevation-0"
                  hide-default-footer
                  :items-per-page="-1"
                  disable-initial-sort
                  hide-actions
                >
                  <template v-slot:items="props">
                    <td>
                      <v-list dense color="transparent" style="padding: 0 !important; margin: 0 !important;">
                        <v-list-tile avatar>
                          <v-list-tile-avatar v-if="props.item.file">
                            <img :src="props.item.file.url" :alt="props.item.name">
                          </v-list-tile-avatar>
                          <v-list-tile-avatar v-else :color="props.item.color">
                            <v-icon color="white">{{ props.item.icon }}</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              <strong>{{ props.item.name }}</strong>
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 18px;">{{ props.item.count }}</strong>
                    </td>
                    <td class="text-xs-right">
                      <strong style="font-size: 15px;">{{ props.item.amount | money }}</strong>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12 md6 lg6 xl6 class="pl-md-2">
                <v-card-text>
                  <div style="display: flex; align-items: center;">
                    <v-icon>pie_chart</v-icon>
                    <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.itemCategories.distribution.opened'"></h4>
                  </div>
                  <div>
                    <apexchart
                      width="100%"
                      type="donut"
                      :series="setSeriesAmountByItemCategory(content.statistics.amount_opened_by_item_category)"
                      :options="optionsItemCategories"
                    ></apexchart>
                  </div>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>

          
      </v-container>
    </template>
  </WiView>
</template>

<script>
import OrdersByStageChart from './Elements/OrdersByStageChart'
import DashboardCard from './Elements/DashboardCard'
import OrdersYearlyComparisonChart from './Elements/OrdersYearlyComparisonChart'
import OrdersAmountYearlyComparisonChart from './Elements/OrdersAmountYearlyComparisonChart'
import OrdersAmountByStageChart from './Elements/OrdersAmountByStageChart'
// import RankingPodium from './Elements/RankingPodium'
// import PeriodColumnChart from './Elements/PeriodColumnChart'
export default {
  name: 'ServiceDeskModuleOrderDashboard',
  data: () => ({
    content: {},
    refresh: null,
    dialog: false,
    dateStart: null,
    dateEnd: null,
    apiParams: null,
    options: {},
    headersCollaborators: [
      { text: 'Colaborador', value: 'name', sortable: true },
      { text: 'Quantidade', value: 'count', align: 'right' },
      { text: 'Valor Total', value: 'amount', align: 'right' }
    ],
    headersCategories: [
      { text: 'Categoria', value: 'name', sortable: true },
      { text: 'Quantidade', value: 'count', align: 'right' },
      { text: 'Valor Total', value: 'amount', align: 'right' }
    ],
    optionsCollaborators: {
      chart: {
        id: 'Ordens por Colaborador'
      },
      labels: [],
      tooltip: {
        y: {
          formatter: function(value) {
            return 'R$ ' + Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      }
    },
    optionsCategories: {
      chart: {
        id: 'Ordens por Categoria'
      },
      labels: [],
      colors: [],
      tooltip: {
        y: {
          formatter: function(value) {
            return 'R$ ' + Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      }
    },
    headersItemCategories: [
      { text: 'Tipo de Item', value: 'name', sortable: true },
      { text: 'Quantidade', value: 'count', align: 'right' },
      { text: 'Valor Total', value: 'amount', align: 'right' }
    ],
    optionsItemCategories: {
      chart: {
        id: 'Ordens por Tipo de Item'
      },
      labels: [],
      colors: [],
      tooltip: {
        y: {
          formatter: function(value) {
            return 'R$ ' + Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      }
    },
  }),
  
  methods: {
    setInitialDateInterval() {
      const today = new Date()
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      
      this.dateStart = firstDay.toISOString().split('T')[0]
      this.dateEnd = lastDay.toISOString().split('T')[0]
    },
    
    setApiParams() {
      if (this.dateStart && this.dateEnd) {
        this.apiParams = `?startDate=${this.dateStart}&endDate=${this.dateEnd}`
      }
    },
    
    changeFilters() {
      this.setApiParams()
      setTimeout(() => {
        this.refresh()
        this.dialog = false
      }, 50);
    },
    
    setSeriesAmountByCollaborator: function (data) {
      const series = []
      const categories = []
      data.forEach((item) => {
        series.push(item.amount)
        categories.push(item.name)
      })
      this.optionsCollaborators.labels = categories
      return series
    },
    
    setSeriesAmountByCategory: function (data) {
      const series = []
      const categories = []
      const colors = []
      data.forEach((item) => {
        series.push(item.amount)
        categories.push(item.name)
        colors.push(item.color)
      })
      this.optionsCategories.labels = categories
      this.optionsCategories.colors = colors
      return series
    },
    setSeriesAmountByItemCategory: function (data) {
      const series = []
      const categories = []
      const colors = []
      data.forEach((item) => {
        series.push(item.amount)
        categories.push(item.name)
        colors.push(item.color)
      })
      this.optionsItemCategories.labels = categories
      this.optionsItemCategories.colors = colors
      return series
    },
    setContent({ content, refresh }) {
      this.content = content
      this.refresh = refresh
    },
    setTablesHeaders: function () {
      this.headersCollaborators = [
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.collaborators.table.headers.collaborator'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.collaborators.table.headers.quantity'),
          value: 'count',
          align: 'right'
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.collaborators.table.headers.totalAmount'),
          value: 'amount',
          align: 'right'
        }
      ]
      this.headersCategories = [
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.categories.table.headers.category'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.categories.table.headers.quantity'),
          value: 'count',
          align: 'right'
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.categories.table.headers.totalAmount'),
          value: 'amount',
          align: 'right'
        }
      ]
    }
  },
  
  created() {
    this.setInitialDateInterval()
    this.setApiParams()
    this.setTablesHeaders()
  },
  
  filters: {
    currency(value) {
      return Number(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  },
  components: {
    DashboardCard,
    OrdersByStageChart,
    OrdersYearlyComparisonChart,
    OrdersAmountYearlyComparisonChart,
    OrdersAmountByStageChart
    // RankingPodium,
    // PeriodColumnChart
  }
}
</script>
