<template>
  <div class="talks-lists">
    <v-toolbar flat extended dense tabs :color="primaryColor" class="white--text elevation-5 talks__toolbar">
      <v-menu :disabled="!$WiCheckPermission('48eab18e')" :close-on-content-click="true" :max-width="275"  style="max-width: 275px;">
        <template v-slot:activator="{ on }">
          <v-toolbar-title v-on="on" class="white--text">
            <span :style="{ cursor: $WiCheckPermission('48eab18e') ? 'pointer' : '' }">
              <span
                v-if="viewMode == 'talks'"
                v-t="'modules.talks.pages.talkView.talks.title'"
              ></span>
              <span
                v-if="viewMode == 'operators'"
                v-t="'modules.talks.pages.talkView.operators.title'"
              ></span>
              <span
                v-if="viewMode == 'channels'"
                v-t="'modules.talks.pages.talkView.channels.title'"
              ></span>
              <span
                v-if="viewMode == 'history'"
                v-t="'modules.talks.pages.talkView.history.title'"
              ></span>
              <v-icon v-if="$WiCheckPermission('48eab18e')" color="white">keyboard_arrow_down</v-icon>

            </span>
          </v-toolbar-title>
        </template>
        <v-card>
          <v-list flat dense>
            <v-list-tile avatar @click="setViewMode('talks')">
              <v-list-tile-avatar>
                <v-icon large>message</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content class="ml-1">
                <v-list-tile-title style="font-size:18px;">
                  <span v-t="'modules.talks.pages.talkView.talks.title'"></span>
                </v-list-tile-title>
                <v-list-tile-sub-title style="font-size: 14px;">
                  Exibir lista de conversas
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile avatar @click="setViewMode('operators')">
              <v-list-tile-avatar>
                <v-icon large>person</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content class="ml-1">
                <v-list-tile-title style="font-size:18px;">
                  <span v-t="'modules.talks.pages.talkView.operators.title'"></span>
                </v-list-tile-title>
                <v-list-tile-sub-title style="font-size: 14px;">
                  Exibir lista de operadores
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile avatar @click="setViewMode('channels')">
              <v-list-tile-avatar>
                <v-icon large>phone_iphone</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content class="ml-1">
                <v-list-tile-title style="font-size:18px;">
                  <span v-t="'modules.talks.pages.talkView.channels.title'"></span>
                </v-list-tile-title>
                <v-list-tile-sub-title style="font-size: 14px;">
                  Exibir lista de canais
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile avatar @click="setViewMode('history')">
              <v-list-tile-avatar>
                <v-icon large>history</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content class="ml-1">
                <v-list-tile-title style="font-size:18px;">
                  <span v-t="'modules.talks.pages.talkView.history.title'"></span>
                </v-list-tile-title>
                <v-list-tile-sub-title style="font-size: 14px;">
                  Exibir lista de histórico
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>


      </v-menu>

      <v-spacer></v-spacer>
      <v-tooltip bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="searchTalkContact()">
            <v-icon class="white--text">search</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.talks.actions.search.tooltip'"></span>
      </v-tooltip>
      <v-tooltip bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="newTalkContact()">
            <v-icon class="white--text">add</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.talks.actions.add.tooltip'"></span>
      </v-tooltip>
      <v-tooltip bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple link :to="{name: 'talk-list', params: {page: 'talk-closed'}}">
            <v-icon class="white--text">history</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.talks.actions.history.tooltip'"></span>
      </v-tooltip>
      <v-tooltip v-if="viewMode == 'talks'" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="teamsDialog = true" class="white--text">
            <v-icon class="white--text">groups</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.talks.actions.teams.tooltip'"></span>
      </v-tooltip>
      <v-tooltip v-if="viewMode == 'talks'" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="getTalks()" class="white--text" :loading="loadings.load">
            <v-icon class="white--text">refresh</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.talks.actions.refresh.tooltip'"></span>
      </v-tooltip>
      <v-tooltip v-if="viewMode == 'operators'" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="getOperators()" class="white--text" :loading="loadings.load">
            <v-icon class="white--text">refresh</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.operators.actions.refresh.tooltip'"></span>
      </v-tooltip>
      <v-tooltip v-if="viewMode == 'channels'" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="refreshChannels()" class="white--text" :loading="loadings.load">
            <v-icon class="white--text">refresh</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.channels.actions.refresh.tooltip'"></span>
      </v-tooltip>
      <v-tooltip v-if="viewMode == 'history'" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="getHistoryTalks()" class="white--text" :loading="loadings.history">
            <v-icon class="white--text">refresh</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.history.actions.refresh.tooltip'"></span>
      </v-tooltip>
      <v-tooltip v-if="drawer" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small ripple @click="TOUGGLE_DRAWER_TALKS_ACTIVE()" class="white--text">
            <v-icon class="white--text">close</v-icon>
          </v-btn>
        </template>
        <span v-t="'modules.talks.pages.talkView.talks.actions.close.tooltip'"></span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-if="viewMode == 'talks'" v-model="tabs" :color="primaryColor" class="talks__toolbar-tabs" grow>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab> 
            <v-badge right color="white">
              <template v-slot:badge>
                <span v-if="unreadsQueue" class="primary--text">
                  <span v-if="unreadsQueue > 9">+9</span>
                  <span v-else v-text="unreadsQueue"></span>
                </span>
              </template>
              <span v-t="'modules.talks.pages.talkView.talks.tabs.queue.title'"></span>
            </v-badge>
          </v-tab>
          <v-tab> 
            <v-badge right color="white">
              <template v-slot:badge>
                <span v-if="unreadsMyTalks" class="primary--text">
                  <span v-if="unreadsMyTalks > 9">+9</span>
                  <span v-else v-text="unreadsMyTalks"></span>
                </span>
              </template>
              <span v-t="'modules.talks.pages.talkView.talks.tabs.myTalks.title'"></span>
            </v-badge>
          </v-tab>
          <v-tab v-if="$WiCheckPermission('48eab18e')"> 
            <v-badge right color="white">
              <template v-slot:badge>
                <span v-if="unreadsOthers" class="primary--text">
                  <span v-if="unreadsOthers > 9">+9</span>
                  <span v-else v-text="unreadsOthers"></span>
                </span>
              </template>
              <span v-t="'modules.talks.pages.talkView.talks.tabs.others.title'"></span>
            </v-badge>
          </v-tab>
          <v-tab v-if="$WiCheckPermission('48eab18e')"> 
            <v-badge right color="white">
              <template v-slot:badge>
                <span v-if="unreadsGroups" class="primary--text">
                  <span v-if="unreadsGroups > 9">+9</span>
                  <span v-else v-text="unreadsGroups"></span>
                </span>
              </template>
              <span v-t="'modules.talks.pages.talkView.talks.tabs.groups.title'"></span>
            </v-badge>
          </v-tab>
        </v-tabs>
        <div v-if="viewMode == 'operators'" style="padding: 0 15px; color: white;">
          <div v-if="!selectedOperator" style="display: flex; gap: 5px; align-items: center;">
            <div v-if="!showOperatorSearch" style="display: flex; gap: 5px; align-items: center;">
              <v-icon color="white">ads_click</v-icon>
              <span>Selecione para listar os atendimentos</span>
              <v-btn icon small @click="showOperatorSearch = true">
                <v-icon color="white">search</v-icon>
              </v-btn>
            </div>
            <div v-else style="display: flex; align-items: center; width: 100%;">

              <v-btn icon small @click="closeOperatorSearch">
                <v-icon color="white">arrow_back</v-icon>
              </v-btn>
              <v-text-field
                v-model="operatorSearchTerm"
                dense
                flat
                solo-inverted
                hide-details
                clearable autofocus
                placeholder="Buscar operadores..."
                prepend-inner-icon="search"
                class="talks-lists-search-field"
                style="color: white;"
              ></v-text-field>
            </div>
          </div>
          <div v-else style="display: flex; justify-content: space-between; align-items: center;">
            <v-btn icon @click="SELECTED_OPERATOR(null)">
              <v-icon color="white">arrow_back</v-icon>
            </v-btn>
            <span style="color: white;">
              <span>Operador: </span>
              <strong>
                {{ selectedOperator.name }}
              </strong>
            </span>
          </div>
        </div>
        <div v-if="viewMode == 'channels'" style="padding: 0 15px; color: white;">
          <div v-if="!selectedChannel" style="display: flex; gap: 5px; align-items: center;">
            <div v-if="!showChannelSearch" style="display: flex; gap: 5px; align-items: center;">
              <v-icon color="white">ads_click</v-icon>
              <span>Selecione para listar os atendimentos</span>
              <v-btn icon small @click="showChannelSearch = true">
                <v-icon color="white">search</v-icon>
              </v-btn>
            </div>
            <div v-else style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
              <v-btn icon small @click="closeChannelSearch">
                <v-icon color="white">arrow_back</v-icon>
              </v-btn>
              <v-text-field
                v-model="channelSearchTerm"
                dense
                flat
                height="32"
                solo-inverted
                hide-details
                clearable autofocus
                placeholder="Buscar canais..."
                prepend-inner-icon="search"
                class="talks-lists-search-field"
                style="color: white;"
              ></v-text-field>
            </div>
          </div>
          <div v-else style="display: flex; justify-content: space-between; align-items: center;">
            <v-btn icon @click="SELECTED_CHANNEL(null)">
              <v-icon color="white">arrow_back</v-icon>
            </v-btn>
            <span style="color: white;">
              <span>Canal: </span>
              <strong>
                {{ selectedChannel.name }}
              </strong>
              <v-icon :color="selectedChannel.status_color" small>{{ selectedChannel.status_icon }}</v-icon>
            </span>
          </div>
        </div>
        <div v-if="viewMode == 'history'" style="margin: 0 10px; width: 100%; display: flex; justify-content: space-between; align-items: center;">
          <span style="color: white; display: flex; gap: 5px; align-items: center;">
            <v-icon color="white">history</v-icon>
            <span>Exibindo conversas<br>concluídas</span>
          </span>
          <WiListFilters
            :config="talkClosed.filters"

            :params="{}"
            @filter="filters => { setHistoryTalksFilters(filters) }"
            :disabled="loadings.history"

          ></WiListFilters>
        </div>
      </template>
    </v-toolbar>
    <v-tabs-items v-if="viewMode == 'talks'" v-model="tabs">
      <v-tab-item lazy>
        <TalksList type="queue"></TalksList>
      </v-tab-item>
      <v-tab-item lazy>
        <TalksList type="my-talks"></TalksList>
      </v-tab-item>
      <v-tab-item v-if="$WiCheckPermission('48eab18e')" lazy>
        <TalksList type="others"></TalksList>
      </v-tab-item>
      <v-tab-item v-if="$WiCheckPermission('48eab18e')" lazy>
        <TalksList type="groups"></TalksList>
      </v-tab-item>
    </v-tabs-items>
    <div v-else-if="viewMode == 'operators'">
      <TalksOperators :searchTerm="operatorSearchTerm"></TalksOperators>
    </div>
    <div v-else-if="viewMode == 'channels'">
      <TalksChannels :searchTerm="channelSearchTerm"></TalksChannels>
    </div>
    <div v-else-if="viewMode == 'history'">
      <TalksHistory></TalksHistory>
    </div>
    <v-dialog v-model="teamsDialog" :dark="$wiDark" scrollable max-width="500px" lazy>
      <v-card>
        <v-toolbar dense flat color="transparent">

          <v-toolbar-title
            v-t="'modules.talks.pages.talkView.teamsFilter.title'"
          ></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="teamsDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="padding: 0; max-height: 90%;">
          <v-list v-if="teams && teams.length > 0" dense two-line style="padding: 0;">
            <template v-for="(team, teamIndex) in teams">
              <v-divider v-if="teamIndex" :key="`${teamIndex}--divider`"></v-divider>
              <v-list-tile
                @click="touggleTeam(team)"
                :key="`${teamIndex}--tile`"
              >
                <v-list-tile-action>
                  <v-checkbox
                    large
                    color="primary"
                    v-model="team.selected"
                    @change="touggleTeam(team)"
                  ></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong :style="{
                      fontSize: '18px',
                      padding: '0px 10px',
                      borderRadius: '5px',
                      background: team.color,
                      color: $WiColorContrast(team.color),
                      borderStyle: 'solid',
                      borderColor: $WiColorContrast(team.color),
                      borderWidth: '1px'
                    }">
                      {{ team.name }}
                    </strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    <span>
                      <span
                        v-t="'modules.talks.pages.talkView.teamsFilter.messages.select.click'"
                      ></span>
                      <span
                        v-if="team.selected"
                        v-t="'modules.talks.pages.talkView.teamsFilter.messages.select.unselected'"
                      ></span>
                      <span
                        v-else
                        v-t="'modules.talks.pages.talkView.teamsFilter.messages.select.selected'"
                      ></span>
                    </span>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
          <div v-else style="text-align: center; margin: 20px;">
            <v-card-text>
              <h3 v-t="'modules.talks.pages.talkView.teamsFilter.messages.teamsNotFound.title'"></h3>
              <br>
              <h4 style="color: gray;" v-t="'modules.talks.pages.talkView.teamsFilter.messages.teamsNotFound.text'"></h4>
            </v-card-text>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>    
</template>
<script>
  import talkViewMixin from '../talkViewMixin'
  import TalksOperators from './TalksOperators.vue'
  import TalksChannels from './TalksChannels.vue'
  import TalksHistory from './TalksHistory.vue'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import TalksList from '@/modules/talk/pages/TalkView/Talks/TalksList'
  import WiListFilters from '@/default/component/WiList/Filters'
  export default {
    name: 'TalksLists',
    data () {
      return {
        tabs: 1,
        viewMode: 'talks',
        teamsDialog: false,
        showOperatorSearch: false,
        showChannelSearch: false,
        operatorSearchTerm: '',
        channelSearchTerm: ''
      }
    },
    computed: {
      ...mapState('pages', {talkClosed: 'talk-closed'}),
      ...mapState('Talks', ['talks', 'loadings', 'teams', 'selectedOperator', 'selectedChannel']),
      ...mapGetters('Talks', ['groups', 'unreadsQueue', 'unreadsMyTalks', 'unreadsOthers', 'unreadsGroups', 'selectedTeams']),
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapMutations('Pages/Main', ['TOUGGLE_DRAWER_TALKS_ACTIVE']),
      ...mapMutations('Talks', ['TOUGGLE_TEAM', 'SELECTED_OPERATOR', 'SELECTED_CHANNEL']),
      ...mapActions('Talks', ['getTalks', 'paginateTalks', 'getOperators', 'getChannels', 'getHistoryTalks', 'setTalksFilters']),
      searchTalkContact: function () {
        this.$WiListDialog({
          wiConfig: 'talk-contact-all',
          mode: 'select',
          callback: (contact) => {
            this.openTalkContact(contact)
          },
          filter: { order: 'last_event_at,desc' }
        })
      },
      newTalkContact: function () {
        this.$WiEditDialog({
          wiConfig: 'talk-contact',
          onSubmit: ({ status, data }) => {
            if (status === 201) {
              this.createNewTalk(data)
            }
          },
          redirectOnSubmit: false
        })
      },
      changeTeam: function (team) {
        this.TOUGGLE_TEAM(team)
      },
      touggleTeam: function (team) {
        this.TOUGGLE_TEAM({
          ...team,
          selected: !team.selected
        })
      },
      setViewMode: function (viewMode) {
        if (this.$WiCheckPermission('48eab18e')) {
          this.viewMode = viewMode
          if (viewMode === 'channels') {
            this.SELECTED_OPERATOR(null)
          } else if (viewMode === 'operators') {
            this.SELECTED_CHANNEL(null)
          }
        }
      },
      refreshChannels: function () {
        if (this.selectedChannel) {
          this.paginateTalks()
        } else {
          this.getChannels()
        }
      },
      closeOperatorSearch() {
        this.showOperatorSearch = false
        this.operatorSearchTerm = ''
      },
      closeChannelSearch() {
        this.showChannelSearch = false
        this.channelSearchTerm = ''
      },
      setHistoryTalksFilters: function (filters) {
        this.setTalksFilters(filters)
        this.getHistoryTalks()
      }
    },
    props: ['drawer'],

    components: {
      TalksList,
      TalksOperators,
      TalksChannels,
      TalksHistory,
      WiListFilters
    },
    mixins: [talkViewMixin]

  }
</script>
<style>
  .talks__toolbar {
    z-index: 1 !important;
  }
  .talks__toolbar .v-toolbar__extension {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .talks-lists .v-toolbar__content, .v-toolbar__extension {
    padding: 0 14px !important;
  }
  .talks-lists .v-toolbar__content .v-btn--icon, .v-toolbar__extension .v-btn--icon {
    margin: 2px !important;
  }
  .talks__toolbar-tabs {
    color: white;
  }
  .talks__toolbar-tabs .v-tabs__wrapper {
    color: white;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .talks__toolbar-tabs .v-tabs__item {
    color: white !important;
  }
  .talks__toolbar-tabs .v-tabs__item {
    font-size: 12px;
  }
  .talks-lists-search-field {
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 4px;
    max-width: 300px;
    transition: all 0.3s ease;
  }
  .talks-lists-search-field.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .talks-lists-search-field .v-input__slot {
    transition: background-color 0.3s ease;
    min-height: 48px !important;
  }
  .talks-lists-search-field .v-input__slot input {
    color: white !important;
  }
  .talks-lists-search-field .v-input__slot .v-icon {
    color: white !important;
  }
  .talks-lists-search-field .v-input__slot input::placeholder {
    color: white !important;
    opacity: 0.7;
  }
  .talks-lists-search-field .v-input__slot {
    padding: 0 8px !important;
  }
  .talks-lists-search-field .v-input__slot .v-input__prepend-inner {
    margin-top: 4px !important;
  }
</style>
