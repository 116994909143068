import { ContractFilters } from './ContractFilters'
import { Contract } from '@/modules/business/config/Contract/Contract'

export class ContractSales extends Contract {
  constructor () {
    super()
    this.apiUrl = 'saless/contract'
    this.urlDetails.name = 'contract'
    this.filters = new ContractFilters()
    this.components.list.tableRow = () => import('@/modules/sales/pages/Contracts')
    this.fieldsEdit.status.apiUrl = 'sales/config/status/contract'
    this.fieldsEdit.category.apiUrl = 'sales/config/category/contract'
  }
}

export class ContractOpened extends ContractSales {
  constructor () {
    super()
    this.title.list = 'Contratos Em Análise'
    this.params.stage = 'open'
    this.urlDetails.name = 'contract'
  }
}
export class ContractClosed extends ContractSales {
  constructor () {
    super()
    this.title.list = 'Contratos Assinados'
    this.params.stage = 'close'
    this.params.is_recurrent = 'no'
    this.urlDetails.name = 'contract'
  }
}

export class ContractRecurrent extends ContractSales {
  constructor () {
    super()
    this.title.list = 'Contratos Recorrentes Ativos'
    this.urlDetails.name = 'contract'
    this.params.stage = 'close'
    this.params.is_recurrent = 'yes'
    this.params.with = 'invoices_late:id,status,business_id,date_validity,in_out'
    this.components.list.tableRow = () => import('@/modules/sales/pages/Contracts/ContractsRecurrent')
    this.clearFields()
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    personName: this.fieldList({
      value: 'person.name',
      text: 'Nome do Cliente',
      sortable: false
    }),
    status: this.fieldList({
      value: 'business_status_id',
      text: 'Status/Etapa',
      align: 'center'
    }),
    category: this.fieldList({
      value: 'business_category_id',
      text: 'Categoria/Tipo',
      align: 'center'
    }),
    invoice_pay_day: this.fieldList({
      value: 'invoice_pay_day',
      text: 'Dia de pgto.',
      align: 'center'
    }),
    finance_situation: this.fieldList({
      value: 'finance_situation',
      text: 'Situação',
      sortable: true,
      align: 'center'
    }),
    end_date: this.fieldList({
      value: 'end_date',
      text: 'Encerramento',
      sortable: true,
      align: 'center'
    }),
    total_liquid: this.fieldList({
      value: 'total_liquid',
      text: 'Valor',
      sortable: true,
      align: 'center'
    })
  }
}

export class ContractCanceled extends ContractSales {
  constructor () {
    super()
    this.urlDetails.name = 'contract'
    this.title.list = 'Contratos Cancelados'
    this.params.stage = 'cancel'
  }
}
