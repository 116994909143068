<template>
  <v-layout row wrap justify-center>
    <v-flex xs2 sm1 md1 lg1 xl1 text-xs-center white--text>
      <v-select
        v-model="limit"
        :items="[5, 10, 20, 50, 100]"
        class="padding-inputtt"
        label="Select"
        hide-details
        single-line
        solo style="z-index: 880;"
      ></v-select>
    </v-flex>
    <v-flex xs8 text-xs-center white--text>
      <div class="mt-1">
        <v-pagination
          v-model="page"
          :disabled="config.loading"
          :length="config.lastPage"
          color="primary"
          total-visible="5"
        ></v-pagination>
      </div>
    </v-flex>
    <v-flex v-if="config.totalItems" xs2 text-xs-right class="hidden-xs-only hidden-sm-only wi-list-pagination__info">
      <h5 class="mt-1">
        <span>Exibindo {{ config.from | number }} até {{ config.to | number }} </span>
        <br>
        <span> de {{ config.totalItems | number }} registro(s) </span>

      </h5>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    name: 'WiListPagination',
    computed: {
      page: {
        get () {
          return parseInt(this.config.params.page)
        },
        set (value) {
          this.config.setParams({page: value}, true)
          if (this.setRoute) {
            this.$router.push({query: this.config.params})
          }
        }
      },
      limit: {
        get () {
          return parseInt(this.config.params.limit)
        },
        set (value) {
          this.config.setParams({limit: value, page: 1}, true)
          if (this.setRoute) {
            this.$router.push({query: this.config.params})
          }
        }
      }
    },
    props: {
      'config': {
        type: Object,
        required: true
      },
      'setRoute': {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
  .wi-list-pagination__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>