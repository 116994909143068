<template>
  <view-detail title="Detalhes do Chamado" :apiUrl="'service-desk/ticket'" :apiParams="'?join=interacao'" :notShowToolbar="false">
    <template slot="toolbarItensBefore" slot-scope="dados">
      <v-btn
        small
        outline
        color="white"
        v-prms="{'id': 'a5243554'}"
        class="hidden-xs-only hidden-sm-only"
        @click="$WiEditDialog({wiConfig: 'chamados-anotacao', onSubmit: dados.refresh, data: {chamado_dados_id: dados.content.id}})" 
        :disabled="dados.loading || dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Anotação</span>
      </v-btn>
      <v-btn
        icon
        v-prms="{'id': 'a5243554'}"
        class="hidden-xs-only hidden-sm-only"
        @click="editSettings(dados.content, dados.refresh)"
        :disabled="dados.loading || dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3"
      >
        <v-icon color="white">tune</v-icon>
      </v-btn>
      <v-btn
        icon
        v-prms="{'id': '8d7c5c53'}"
        class="hidden-xs-only hidden-sm-only"
      >
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="dados.loading || dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3"
            >
              <v-icon color="white">settings</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader>Ações do chamado</v-subheader>
            <v-divider></v-divider>
            <v-list-tile @click="handleRemove(dados.content.id, 'devices')">
              <v-list-tile-action>
                <v-icon>devices</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>Remover todos os dispositivos deste chamado</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="handleRemove(dados.content.id, 'networks')">
              <v-list-tile-action>
                <v-icon>wifi</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>Remover todas as redes deste chamado</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="handleRemove(dados.content.id, 'interfaces')">
              <v-list-tile-action>
                <v-icon>settings_ethernet</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>Remover todas as interfaces deste chamado</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="handleRemove(dados.content.id, 'all')">
              <v-list-tile-action>
                <v-icon>delete_sweep</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>Remover todos os dispositivos, redes e interfaces</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-btn>
    </template>
    <template slot-scope="dados">
      <div v-if="dados.content.assunto_chamado" style="padding: 20px !important;">
        <v-card class="elevation-10">
          <v-list-tile avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                <span style="color: gray;">Título/Assunto:</span>
              </v-list-tile-sub-title>
              <v-list-tile-title>
                <strong style="font-size: 20px;">
                {{ dados.content.assunto_chamado }}
              </strong>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn
                icon
                @click="
                $WiEditDialog({
                  'wiConfig': 'ticket-title',
                  data: {
                    id: dados.content.id,
                    assunto_chamado: dados.content.assunto_chamado,
                  },
                  onSubmit: dados.refresh
                })"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-card>
      </div>
      <div style="padding: 20px !important;">
        <ticket-info :dados="dados"></ticket-info>
      </div>
      <div class="padding_chamado" style="padding-bottom: 50px;">
        <v-expansion-panel popout v-model="panel" expand >
          <!-- <v-expansion-panel-content v-prms="{'id': 'd237fbcd-2a0d-42eb-beef-409d29813ad3'}" class="elevation-17" value="teste2">
            <template slot="header">
              <strong style="color: gray;">
                <v-icon>device_hub</v-icon>
                <span> Redes e dispositivos anexados</span>
              </strong>
            </template>
            <v-divider></v-divider>
            <div v-if="panel[0]">
              <chamado-network :dados="dados"></chamado-network>
            </div>
          </v-expansion-panel-content> -->
          <v-expansion-panel-content class="elevation-17" value="teste3">
            <template slot="header">
              <strong style="color: gray;">
                <v-icon>timeline</v-icon>
                <span> Linha do tempo de interações</span>
              </strong>
            </template>
            <div v-if="panel[0]">
              <v-divider></v-divider>
              <chamado-time-line :dados="dados"></chamado-time-line>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </div>
      <v-btn @click="$WiEditDialog({wiConfig: 'chamados-anotacao', onSubmit: dados.refresh, data: {chamado_dados_id: dados.content.id}})" color="green" class="white--text hidden-md-only hidden-lg-only hidden-xl-only elevation-17" fab fixed bottom right>
        <v-icon>add</v-icon>
      </v-btn>
    </template>
  </view-detail>
</template>

<script>
  import ViewDetail from '@/default/components/ViewDetail'
  import TicketInfo from '@/modules/service-desk/pages/Ticket/Info/TicketInfo'
  // import ChamadoNetwork from '@/modules/service-desk/pages/Ticket/Network/ChamadoNetwork'
  import ChamadoTimeLine from '@/modules/service-desk/pages/Ticket/Events/ChamadoTimeLine'
  export default {
    data: () => ({
      panel: [true]
    }),
    methods: {
      editSettings: function (ticket, refresh) {
        const { id, check_dashboards, check_hosts, check_monitoring, check_reports } = ticket
        this.$WiEditDialog({
          wiConfig: 'ticket-settings',
          onSubmit: refresh,
          data: { id, check_dashboards, check_hosts, check_monitoring, check_reports },
        })
      },
      handleRemove(ticketId, type) {
        this.$WiApiPut({
          uri: `service-desk/ticket/${ticketId}/network-remove`,
          id: type,
          data: {},
          config: {
            msgAsk: true,
            msgLoading: true,
            msgSucess: true,
            msgError: true
          }
        })
      }
    },
    components: {
      TicketInfo,
      ViewDetail,
      // ChamadoNetwork,
      ChamadoTimeLine
    }
  }
</script>
<style>
  @media only screen and (min-width: 500px) {
    .padding_chamado {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
</style>