import { FileFilters } from './FileFilters'
import { Model } from '@/default/model/Model'
export class File extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1d91353e'
    this.name = 'file'
    this.title = {
      list: 'Arquivos',
      edit: {
        insert: 'Novo Arquivo',
        update: 'Editar Arquivo'
      }
    }
    this.apiUrl = 'system/file'
    this.icon = 'attach_file'
    this.search = {
      placeholder: 'Pesquise pelo nome do arquivo',
      fields: ['name', 'title']
    }
    this.filters = new FileFilters()
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '1000px'
    this.components.list.tableRow = () => import('@/default/pages/Common/Sites/Files')
    this.editFields = {
      file_id: this.fieldWiFileDialog({
        value: 'file_id',
        text: 'Imagem de destaque da publicação',
        placeholder: 'Selecione uma imagem para a capa da publicação',
        showField: form => (!form.id),
        options: {
          galery: false,
          upload: true
        }
      }),
      header1: this.fieldSubHeader({
        text: 'Você pode editar o nome, título e alt text (Imagens).',
        showField: form => (!!form.id)
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        showField: form => (!!form.id)
      }),
      title: this.fieldText({
        value: 'title',
        text: 'Título',
        required: false,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        showField: form => (!!form.id)
      }),
      alt_text: this.fieldText({
        value: 'alt_text',
        text: 'Alt Text',
        required: false,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        showField: form => (!!form.id)
      }),
      header2: this.fieldSubHeader({
        text: 'Insira uma descrição',
        showField: form => (!!form.id)
      }),
      description: this.fieldTextArea({
        value: 'description',
        text: 'Descrição',
        placeholder: 'Conteúdo da publicação',
        showField: form => (!!form.id)
      })
    }
    this.listFields = [
      this.fieldList({
        value: 'file',
        text: 'Arquivo',
        align: 'center',
        sortable: false
      }),
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'extension',
        text: 'Extensão'
      }),
      this.fieldList({
        value: 'title',
        text: 'Título'
      }),
      this.fieldList({
        value: 'created_at',
        text: 'Criado em:'
      }),
      this.fieldList({
        value: 'access',
        text: 'Acessar o arquivo',
        sortable: false
      }),
    ]
    this.setFields(this.editFields)
    this.setFields(this.listFields)
  }
}

export class PostFile extends File {
  constructor () {
    super()
    this.actions = {
      list: true,
      create: true,
      update: false,
      delete: true
    }
    this.apiUrl = 'sites/post/file'
    this.apiParams = { is_public: true }

    this.clearFields()

    this.editFields.file_id = this.fieldWiFileDialog({
      value: 'file_id',
      text: 'Imagem de destaque da publicação',
      placeholder: 'Selecione uma imagem para a capa da publicação',
      showField: form => (!form.id),
      data: {
        is_public: true
      },
      options: {
        galery: false,
        upload: true
      }
    })

    this.setFields(this.editFields)
    this.setFields(this.listFields)
  }
}
