<template>
  <div>
    <v-divider v-if="index" :key="`${talk.id}-${index}-divider`" inset></v-divider>
    <v-list-tile
      v-ripple 
      avatar
      :key="`${talk.id}-${index}-talk`"
      :style="{background: getTalkStatusBackground}"
    >
      <!-- Avatar Section -->
      <v-list-tile-avatar @click="$emit('onTalkAction', talk)" style="cursor: pointer;">
        <v-badge overlap right bottom offset-x="22" offset-y="24" color="transparent">
          <template v-slot:badge>
            <ChannelIcon :channelType="talk.channel.type.code" />
          </template>
          <v-avatar>
            <ContactAvatar :contact="talk.contact" />
          </v-avatar>
        </v-badge>
      </v-list-tile-avatar>

      <!-- Content Section -->
      <v-list-tile-content @click="$emit('onTalkAction', talk)" style="cursor: pointer;">
        <TalkListItemHeader 
          :contact="talk.contact"
          :checked="talk.contact.checked"
        />
        
        <TalkListItemLabels
          :channel="talk.channel"
          :team="talk.team"
          :category="talk.category"
        />
        
        <TalkListItemStatus
          :status="talk.status"
          :operator="talk.operator"
        />
      </v-list-tile-content>

      <!-- Action Section -->
      <TalkListItemActions
        :talk="talk"
        :currentRouteId="currentRouteId"
        :onRefresh="onRefresh"
        :random="random"
      />
    </v-list-tile>
  </div>
</template>

<script>
import ChannelIcon from './TalkListItemChannelIcon.vue'
import ContactAvatar from './TalkListItemContactAvatar.vue'
import TalkListItemHeader from './TalkListItemHeader.vue'
import TalkListItemLabels from './TalkListItemLabels.vue'
import TalkListItemStatus from './TalkListItemStatus.vue'
import TalkListItemActions from './TalkListItemActions.vue'

export default {
  name: 'TalkListItem',
  
  components: {
    ChannelIcon,
    ContactAvatar,
    TalkListItemHeader,
    TalkListItemLabels,
    TalkListItemStatus,
    TalkListItemActions
  },

  props: {
    talk: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    currentRouteId: {
      default: null
    },
    onRefresh: {
      type: Function,
      required: true
    },
    random: {
      type: Number,
      default: 0
    }
  },

  computed: {
    getTalkStatusBackground() {
      switch(parseInt(this.talk.status)) {
        case 3: return this.$vuetify.theme.dark ? '#003161' : '#b2dfdb'
        case 4: return '#ffccbc'
      }

      if (this.talk.is_waiting_response) {
        return this.$wiDark ? '#1E1E1E' : '#dfd2b2'
      }

      return ''
    }
  }
}
</script> 