<template>
  <WiCategories
    title="Categorias de Atendimento"
    viewIndex="talks-categories"
    apiEndpoint="talks/category-hierarchy"
    deleteEndpoint="talks/category"
    editConfig="talk-category"
    parentCategoryField="talk_category_id"
    colorField="color"
    iconField="icon"
    @on-edit-category="onEditCategory"
    @on-add-category="onAddCategory"
    @on-add-child-category="onAddChildCategory"
  />
</template>

<script>
import WiCategories from '@/default/component/WiCategories'

export default {
  name: 'talk-categories',
  components: {
    WiCategories
  },
  methods: {
    onEditCategory({ refresh, category }) {
      this.$WiEditDialog({
        wiConfig: 'talk-category',
        data: category,
        redirectOnSubmit: false,
        onSubmit: refresh
      })
    },
    onAddCategory({ refresh }) {
      this.$WiEditDialog({
        wiConfig: 'talk-category',
        data: {},
        redirectOnSubmit: false,
        onSubmit: refresh
      })
    },
    onAddChildCategory({ refresh, parentCategory }) {
      this.$WiEditDialog({
        wiConfig: 'talk-category',
        data: {
          talk_category_id: parentCategory.id,
          color: parentCategory.color,
          icon: parentCategory.icon
        },
        redirectOnSubmit: false,
        onSubmit: refresh
      })
    }
  }
}
</script>
