<template>
  <v-card class="pa-0 elevation-10">
    <v-list v-if="field.type != 'header'" two-line dense style="padding: 0; margin: 0;">
      <v-list-tile
        avatar class="pa-0 wn-register-copy-field__tile"
        :class="{
          'wn-register-copy-field__tile--copied-light': (copiedData && !$wiDark),
          'wn-register-copy-field__tile--copied-dark': (copiedData && $wiDark)
        }"
      >
      
        <v-list-tile-avatar v-if="field.icon" size="20" class="wn-register-copy-field__avatar">
          <v-avatar size="20">
            <v-icon class="mr-0">{{ field.icon }}</v-icon>
          </v-avatar>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>
            {{ field.label }}
          </v-list-tile-sub-title>
          <v-list-tile-title v-if="!field.type">
            <strong>{{ getValue() || '---' }}</strong>
          </v-list-tile-title>
          <v-list-tile-title v-if="field.type == 'money'">
            <strong>{{ getValue() | money }}</strong>
          </v-list-tile-title>
          <v-list-tile-title v-if="field.type == 'date'">
            <strong>
              {{ getValue() | moment('DD/MM/YYYY') }}
            </strong>
          </v-list-tile-title>
          <v-list-tile-title v-if="field.type == 'phone'">
            <strong v-if="getValue().length >= 11">{{ getValue() | mask('(##) ####-####') }}</strong>
            <strong v-else-if="getValue().length >= 10">{{ getValue() | mask('(##) ####-####') }}</strong>
            <strong v-else>{{ getValue() }}</strong>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action v-if="copiedData" class="wn-register-copy-field__action">
          <v-icon v-if="copiedData" small color="green" class="mr-1">
            done_outline
          </v-icon>
        </v-list-tile-action>
        <v-list-tile-action class="wn-register-copy-field__action">
          <wi-copy-to
            v-if="getValue()"
            :text="getValue('copy')"
            :title="getValue('copy')"
            @copied="copied('copy')"
          ></wi-copy-to>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <v-card-text
      v-else
      style="
        padding: 0;
        height: 25px;
        background-color: #5b5b5b;
        color: #fff;
        font-weight: bold;
        margin-bottom: 10px;
        padding-left: 20px;
      "
      :style="{ background: $wiDark ? 'black' : '#5b5b5b' }"
    >
      <h3>{{ field.label }}</h3>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RegisterCopyField',
  data: () => ({
    copiedData: false
  }),
  methods: {
    copied() {
      this.copiedData = true
      this.$emit('field-copied', this.field.key)
    },
    getValue(action = 'show') {
      let valueDate = null
      // verificar se key tem ., se tiver, deve fazer um split e percorrer o array
      if (this.field.key.includes('.')) {
        // Ex: address.city.name = { address: { city: { name: 'São Paulo' } } }
        const keys = this.field.key.split('.')
        if (keys.length == 4) {
          valueDate = this.data?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
        } else if (keys.length == 3) {
          valueDate = this.data?.[keys[0]]?.[keys[1]]?.[keys[2]]
        } else if (keys.length == 2) {
          valueDate = this.data?.[keys[0]]?.[keys[1]]
        } else if (keys.length == 1) {
          valueDate = this.data?.[keys[0]]
        }
        
      } else {
        valueDate = this.data?.[this.field.key]
      }
      // remover espaços em branco a direita
      valueDate = valueDate?.toString()?.trim()
      if (!valueDate && this.field.default) {
        valueDate = this.field.default
      }
      if (this.field.type == 'date') {
        return valueDate ? this.$moment(valueDate).format('DD/MM/YYYY') : '---'
      }
      if (this.field.type == 'money' && action == 'copy') {
        // converte para number e formata com 2 casas decimais
        valueDate = valueDate ? parseFloat(valueDate).toFixed(2) : 0 
      }
      if (this.field.type == 'phone') {
        // remove todos os caracteres que não são dígitos
        valueDate = valueDate ? valueDate.replace(/\D/g, '') : '---'
        // verifica se inicia com 55 e remove o 55 do inicio
        if (valueDate && valueDate?.startsWith('55')) {
          valueDate = valueDate.slice(2)
        }
      }
      return valueDate
    }
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  }
}
</script> 

<style scoped>
.wn-register-copy-field__tile >>> .v-list__tile {
  padding: 0 5px !important;
  height: 40px !important;
}

.wn-register-copy-field__avatar {
  min-width: 30px !important;
}

.wn-register-copy-field__action {
  min-width: 30px !important;
}

.wn-register-copy-field__tile--copied-light {
  background-color: #1ba10057;
}

.wn-register-copy-field__tile--copied-dark {
  background-color: #1ba10057;
}
</style>
