import { Model } from '@/default/model/Model'

class TicketDeviceChild extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '1a825af1'
    this.name = 'ticket-device-child'
    // this.actions = {
    //   insert: true,
    //   update: true,
    //   delete: true
    // }
    this.title = {
      list: 'Interfaces anexadas ao chamado',
      edit: {
        insert: 'Anexar Interface',
        update: 'Editar Interface anexado'
      }
    }
    this.apiUrl = 'service-desk/ticket/device-child'
    this.icon = 'dns'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquisa desabilitada...',
      fields: ['id']
    }
    this.maxWidth = '800px'
    this.redirectOnSubmit = false
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Ticket/Network/TicketDeviceChilds')
    this.setFields([
      this.fieldList({
        value: 'device_name',
        text: 'Dispositivo'
      }),
      this.fieldList({
        value: 'child',
        text: 'Interface'
      }),
      this.fieldWiListDialog ({
        value: 'device_id',
        text: 'Dispositivo',
        show: 'nome_disp',
        required: true,
        wiConfig: 'devices',
        size: [ 'xs12', 'sm12', 'md12', 'lg12' ],
        showField: form => !form.device_id || !!form.selectDevice
      }),
      this.fieldSelect ({
        value: 'child_type',
        text: 'Tipo de Interface',
        required: true,
        options: [
          { value: 'slot', text: 'Placa' },
          { value: 'port', text: 'Porta' }
        ],
        size: [ 'xs12', 'sm12', 'md12', 'lg12' ],
        showField: form => !!form.device_id
      }),
      this.fieldSelectApi ({
        value: 'device_child_id',
        text: 'Placa',
        show: 'name',
        required: true,
        api: {
          url: 'network/child',
          params: form => ({
            device_id: form.device_id,
            type_id: 1,
            order: 'name,asc'
          })
        },
        size: [ 'xs12', 'sm12', 'md12', 'lg12' ],
        showField: form => !!form.device_id && !!form.child_type && form.child_type === 'slot'
      }),
      this.fieldSelectApi ({
        value: 'slot_id',
        text: 'Placa',
        show: 'name',
        required: true,
        api: {
          url: 'network/child',
          params: form => ({
            device_id: form.device_id,
            type_id: 1,
            order: 'name,asc'
          })
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        showField: form => !!form.device_id && !!form.child_type && form.child_type === 'port'
      }),
      this.fieldSelectApi ({
        value: 'device_child_id',
        text: 'Porta',
        show: 'name',
        required: true,
        api: {
          url: 'network/child',
          params: form => ({
            child_id: form.slot_id,
            type_id: 2,
            order: 'name,asc'
          })
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        showField: form => !!form.device_id && !!form.child_type && form.child_type === 'port' && !!form.slot_id,
        dependOn: ['slot_id']
      })
    ])
  }
}
export default new TicketDeviceChild()
