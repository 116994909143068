<template>
  <v-dialog v-model="dialog" scrollable max-width="898px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" outline small color="orange" @click="dialog = true">
        <v-icon>edit</v-icon>
        <span> Definir Modelo</span>
      </v-btn>
    </template>
    <div style="background: white !important; min-width: 898px; padding-bottom: 30px !important;">
      <WiView v-if="dialog" index="process" title="Selecione o modelo do equipamento" apiUrl="network/host/producer/model" :apiParams="'?producer_id=' + host.producer_id" :notGetID="true">
        <template slot="toolbarItensAfter" slot-scope="{ loading }">
          <v-btn icon :loading="loading" @click="dialog = false">
            <v-icon color="white">close</v-icon>
          </v-btn>
        </template>
        <template slot-scope="{ content }">
          <v-container>
            <v-layout v-if="content.data && content.data.length > 0" row wrap>
              <v-flex xs12 d-flex>
                <h3 style="padding-bottom: 50px; text-align: center;">Por favor, selecione um modelo de equipamento</h3>
              </v-flex>
              <v-flex v-for="model in content.data" :key="model.id" xs12 sm12 md3 lg3 xl3 d-flex style="padding-right: 5px;">
                <v-card style="text-align: center; cursor: pointer;" @click="selectModel(model)">
                  <v-card-text style="text-align: center;">
                    <div>
                      <img v-if="model.image" :src="model.image" alt="" style="width: 100px;">
                      <br>
                    </div>
                    <strong>{{ model.name }}</strong>
                    <br>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <span>Clique para analisar</span>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout v-else row wrap align-center justify-center>
              <v-flex xs12>
                <h1 class="mb-5" style="text-align: center;">
                  O equipamento deste cliente ainda não foi homologado.
                </h1>
                <v-btn color="green" large class="white--text" @click="requireHomolog()">
                  <v-icon>description</v-icon>
                  <span class="pl-2">Solicitar homologação e avançar</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
      </WiView>
    </div>
    <ProducerModelDialog
      v-if="selectedModel"
      v-model="producerModelDialog"
      :model="selectedModel"
      :host="host"
      mode="select"
      @onSelectModel="onSelectModel"
    ></ProducerModelDialog>
  </v-dialog>
</template>
<script>
  import ProducerModelDialog from './Dialog'
  export default {
    name: 'ProducerModelSelectDialog',
    data: () => ({
      dialog: false,
      producerModelDialog: false,
      selectedModel: null
    }),
    methods: {
      selectModel: function (model) {
        this.selectedModel = model
        this.producerModelDialog = true
      },
      onSelectModel: function (selectedModel) {
        this.$emit('onSelectModel', selectedModel)
        this.dialog = false
      },
      requireHomolog: function () {
        // this.$WiHelpDialog({
        //   title: 'Descreva a solicitação de homologação.',
        //   subject: 'homolog',
        //   message: this.getHomologMessage(),
        //   callback: () => {
        //     this.onSelectModel(null)
        //   }
        // })
        this.onSelectModel(null)
      },
      getHomologMessage: function () {
        return `Favor homologar o equipamento:\n\n- Código: ${this.host?.id || '- - -'}\n- Tecnologia: ${this.host?.technology?.name || '- - -'}\n- MAC: ${this.host?.mac_address || '- - -'}\n- Serial: ${this.host?.serial || '- - -'}\n\n`
      }
    },
    props: {
      host: {
        required: true,
        default: () => ({})
      }
    },
    components: {
      ProducerModelDialog
    }
  }
</script>
<style scoped>
  .producer-model-subheader__button {
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
    }
  }
</style>
