<template>
  <WiView
    v-if="apiParams"
    index="order-dashboard"
    :apiUrl="'service-desk/order/dashboard-by-month'"
    :apiParams="apiParams"
    :notShowToolbar="true"
    :notGetID="true"
    @afterLoad="setContent"
  >
    <template slot-scope="{ content, refresh }">
      <v-container style="padding: 10px; margin-bottom: 100px;" fluid grid-list-md>
        <v-layout row wrap>
          <!-- Header -->
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-btn icon ripple @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-list-tile v-ripple avatar class="hidden-xs-only hidden-sm-only">
                <v-list-tile-avatar @click="refresh()" style="cursor: pointer;" size="45">
                  <img src="static/systems/modules/wises-technology-module-service-desk.png" alt="Dashboard Ordens de Serviço">
                </v-list-tile-avatar>
                <v-list-tile-content @click="refresh()" style="cursor: pointer;">
                  <v-list-tile-sub-title style="font-size: 12px;">
                    <span v-t="'modules.serviceDesk.pages.orderDashboard.header.module'"></span>
                  </v-list-tile-sub-title>
                  <v-list-tile-title style="font-size:18px;">
                    <span v-t="'modules.serviceDesk.pages.orderDashboard.header.title'"></span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-spacer></v-spacer>
              
              <!-- Filtro de Mês -->
              <v-dialog v-model="dialog" width="400">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outline small class="date-selector">
                    <v-icon left>event</v-icon>
                    <span class="pr-2">{{ selectedMonth | formatMonth }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title v-t="'modules.serviceDesk.pages.orderDashboard.header.monthFilter.dialog.title'"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="selector-container">
                    <!-- Seletor de Mês -->
                    <v-menu
                      ref="menuMonth"
                      v-model="menuMonth"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <div 
                          v-on="on"
                          class="selector-field"
                        >
                          <div class="selector-display">
                            <v-icon class="mr-2">today</v-icon>
                            <span class="selector-text">{{ selectedMonth | formatMonth }}</span>
                          </div>
                          <v-icon>keyboard_arrow_down</v-icon>
                        </div>
                      </template>
                      <v-date-picker
                        v-model="selectedMonth"
                        type="month"
                        no-title
                        scrollable
                        locale="pt-BR"
                        :max="maxDate"
                        @input="handleDateSelection"
                      >
                      </v-date-picker>
                    </v-menu>

                    <!-- Seletor de Categoria -->
                    <v-menu
                      ref="menuCategory"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <div 
                          v-on="on"
                          class="selector-field"
                        >
                          <div class="selector-display">
                            <v-icon 
                              class="mr-2"
                              :color="selectedCategory ? selectedCategory.color : 'grey'"
                            >
                              {{ selectedCategory ? selectedCategory.icon : 'category' }}
                            </v-icon>
                            <span class="selector-text">
                              {{ selectedCategory ? selectedCategory.name : $t('modules.serviceDesk.pages.orderDashboard.header.categoryFilter.placeholder') }}
                            </span>
                          </div>
                          <v-icon>keyboard_arrow_down</v-icon>
                        </div>
                      </template>
                      <v-card>
                        <v-list>
                          <v-list-tile
                            v-for="category in content.categories"
                            :key="category.id"
                            @click="handleCategorySelection(category)"
                          >
                            <v-list-tile-action>
                              <v-icon :color="category.color">{{ category.icon }}</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                              <v-list-tile-title>{{ category.name }}</v-list-tile-title>
                            </v-list-tile-content>
                            <v-list-tile-action v-if="selectedCategory && selectedCategory.id === category.id">
                              <v-icon color="green">check</v-icon>
                            </v-list-tile-action>
                          </v-list-tile>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn block outline color="green" @click="changeFilters()">
                      <v-icon>filter_list</v-icon>
                      <span>Filtrar</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          
          <!-- Card Informativo -->
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-card class="info-card elevation-10" @click="dialog = true" style="cursor: pointer;">
              <v-card-text class="info-content">
                <div class="info-icon">
                  <v-icon large color="primary">info_outline</v-icon>
                </div>
                <div class="info-text">
                  <span class="text-body">
                    Exibindo resultados para 
                    <span class="highlight">{{ selectedMonth | formatMonth }}</span>
                    <template v-if="selectedCategory">
                      na categoria 
                      <span class="category-chip" :style="{ backgroundColor: selectedCategory.color + '15' }">
                        <v-icon small :color="selectedCategory.color">{{ selectedCategory.icon }}</v-icon>
                        <span :style="{ color: selectedCategory.color }">{{ selectedCategory.name }}</span>
                      </span>
                    </template>
                  </span>
                </div>
                <div class="filter-icon">
                  <v-icon color="grey darken-1">filter_list</v-icon>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          
        </v-layout>

        <!-- Substituir o antigo card pelo novo componente -->
        <OrdersByStageChart
          v-if="content.statistics"
          :data="content.statistics.orders_by_stage"
        />
        <!-- Add after OrdersByStageChart -->
        <OrdersYearlyComparisonChart
          v-if="content.statistics && content.statistics.orders_by_stage_yearly"
          :data="content.statistics.orders_by_stage_yearly"
        />
        <OrdersAmountYearlyComparisonChart
          v-if="content.statistics && content.statistics.orders_amount_by_stage_yearly"
          :data="content.statistics.orders_amount_by_stage_yearly"
        />

        <!-- Add after OrdersByStageChart -->
        <OrdersAmountByStageChart
          v-if="content.statistics"
          :data="content.statistics.orders_amount_by_stage"
        />
      </v-container>
    </template>
  </WiView>
</template>

<script>
import OrdersByStageChart from './Elements/OrdersByStageChart'
import OrdersYearlyComparisonChart from './Elements/OrdersYearlyComparisonChart'
import OrdersAmountYearlyComparisonChart from './Elements/OrdersAmountYearlyComparisonChart'
import OrdersAmountByStageChart from './Elements/OrdersAmountByStageChart'
export default {
  name: 'OrdersByMonthDashboard',
  data: () => ({
    content: {},
    refresh: null,
    dialog: false,
    menu: false,
    selectedMonth: null,
    apiParams: null,
    maxDate: new Date().toISOString().substr(0, 7), // Limita até o mês atual
    options: {},
    headersCollaborators: [
      { text: 'Colaborador', value: 'name', sortable: true },
      { text: 'Quantidade', value: 'count', align: 'right' },
      { text: 'Valor Total', value: 'amount', align: 'right' }
    ],
    headersCategories: [
      { text: 'Categoria', value: 'name', sortable: true },
      { text: 'Quantidade', value: 'count', align: 'right' },
      { text: 'Valor Total', value: 'amount', align: 'right' }
    ],
    optionsCollaborators: {
      chart: {
        id: 'Ordens por Colaborador'
      },
      labels: [],
      tooltip: {
        y: {
          formatter: function(value) {
            return 'R$ ' + Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      }
    },
    optionsCategories: {
      chart: {
        id: 'Ordens por Categoria'
      },
      labels: [],
      colors: [],
      tooltip: {
        y: {
          formatter: function(value) {
            return 'R$ ' + Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      }
    },
    headersItemCategories: [
      { text: 'Tipo de Item', value: 'name', sortable: true },
      { text: 'Quantidade', value: 'count', align: 'right' },
      { text: 'Valor Total', value: 'amount', align: 'right' }
    ],
    optionsItemCategories: {
      chart: {
        id: 'Ordens por Tipo de Item'
      },
      labels: [],
      colors: [],
      tooltip: {
        y: {
          formatter: function(value) {
            return 'R$ ' + Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      }
    },
    menuMonth: false,
    menuCategory: false,
    selectedCategory: null,
    categories: [],
  }),
  
  methods: {
    setInitialMonth() {
      // Define o mês atual como padrão
      this.selectedMonth = new Date().toISOString().substr(0, 7)
    },
    
    setApiParams() {
      let params = `?month=${this.selectedMonth}`
      if (this.selectedCategory) {
        params += `&category_id=${this.selectedCategory.id}`
      }
      this.apiParams = params
    },
    
    handleDateSelection(date) {
      this.selectedMonth = date
      this.menu = false // Fecha o menu após a seleção
    },
    
    handleCategorySelection(category) {
      this.selectedCategory = category
      this.menuCategory = false
    },
    
    changeFilters() {
      this.setApiParams()
      this.dialog = false
      this.$nextTick(() => {
        if (this.refresh) {
          this.refresh()
        }
      })
    },
    
    setSeriesAmountByCollaborator: function (data) {
      const series = []
      const categories = []
      data.forEach((item) => {
        series.push(item.amount)
        categories.push(item.name)
      })
      this.optionsCollaborators.labels = categories
      return series
    },
    
    setSeriesAmountByCategory: function (data) {
      const series = []
      const categories = []
      const colors = []
      data.forEach((item) => {
        series.push(item.amount)
        categories.push(item.name)
        colors.push(item.color)
      })
      this.optionsCategories.labels = categories
      this.optionsCategories.colors = colors
      return series
    },
    setSeriesAmountByItemCategory: function (data) {
      const series = []
      const categories = []
      const colors = []
      data.forEach((item) => {
        series.push(item.amount)
        categories.push(item.name)
        colors.push(item.color)
      })
      this.optionsItemCategories.labels = categories
      this.optionsItemCategories.colors = colors
      return series
    },
    setContent({ content, refresh }) {
      this.content = content
      this.refresh = refresh
      if (content.categories) {
        this.categories = content.categories
      }
    },
    setTablesHeaders: function () {
      this.headersCollaborators = [
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.collaborators.table.headers.collaborator'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.collaborators.table.headers.quantity'),
          value: 'count',
          align: 'right'
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.collaborators.table.headers.totalAmount'),
          value: 'amount',
          align: 'right'
        }
      ]
      this.headersCategories = [
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.categories.table.headers.category'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.categories.table.headers.quantity'),
          value: 'count',
          align: 'right'
        },
        {
          text: this.$t('modules.serviceDesk.pages.orderDashboard.categories.table.headers.totalAmount'),
          value: 'amount',
          align: 'right'
        }
      ]
    }
  },
  
  created() {
    this.setInitialMonth()
    this.setApiParams()
    this.setTablesHeaders()
  },
  
  filters: {
    formatMonth(value) {
      if (!value) return ''
      const [year, month] = value.split('-')
      const date = new Date(year, parseInt(month) - 1)
      return date.toLocaleString('pt-BR', { month: 'long', year: 'numeric' })
        .replace(/^\w/, c => c.toUpperCase()) // Capitaliza primeira letra
    }
  },
  components: {
    OrdersByStageChart,
    OrdersYearlyComparisonChart,
    OrdersAmountYearlyComparisonChart,
    OrdersAmountByStageChart
  }
}
</script>

<style scoped>
.selector-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  background-color: white;
  min-width: 200px;
  margin-bottom: 8px;
}

.selector-field:hover {
  border-color: #2196F3;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.selector-display {
  display: flex;
  align-items: center;
}

.selector-text {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.selector-field:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.selector-field .v-icon {
  color: rgba(0, 0, 0, 0.54);
}

.selector-field:hover .v-icon {
  color: #2196F3;
}

/* Estilo para o botão quando estiver ativo */
.month-selector:active {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Estilo para o ícone */
.month-selector .v-icon {
  color: rgba(0, 0, 0, 0.54);
}

/* Hover no ícone */
.month-selector:hover .v-icon {
  color: #2196F3;
}

.info-card {
  border-radius: 10px;
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  transition: background-color 0.2s ease;
}

.info-card:active {
  background-color: #f5f5f5;
}

.info-content {
  display: flex;
  align-items: center;
  padding: 16px;
}

.info-icon {
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f5f5f5;
}

.filter-icon {
  margin-left: 16px;
  opacity: 0.7;
}

.info-text {
  flex: 1;
}

.text-body {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
}

.highlight {
  font-weight: 500;
  color: #2196F3;
}

.category-chip {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px;
  margin-left: 4px;
  font-weight: 500;
}

.category-chip .v-icon {
  margin-right: 6px;
}

@media (max-width: 600px) {
  .info-content {
    flex-direction: column;
    text-align: center;
  }
  
  .info-icon {
    margin-right: 0;
    margin-bottom: 12px;
  }
  
  .text-body {
    font-size: 14px;
  }
}

.selector-container {
  position: relative;
  z-index: 0; /* Reduz o z-index do container dos seletores */
}
</style>
