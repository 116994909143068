<template>
  <v-toolbar 
    dense 
    :color="$wiDark ? 'dark' : 'white'" 
    flat class="kanban-column-header elevation-10"
  >
    <div class="header-content">
      <!-- Ícone/Botão de Edição -->
      <div class="icon-wrapper">
        <template v-if="!storeStage">
          <v-icon
            :color="stageColor"
            class="stage-icon"
          >{{ stageIcon }}</v-icon>
        </template>
        <template v-else>
          <v-btn 
            icon small flat
            class="edit-button"
            @click="handleEditClick"
          >
            <v-icon :color="stageColor">{{ stageIcon }}</v-icon>
          </v-btn>
        </template>
      </div>

      <!-- Título e Total -->
      <div class="info-wrapper">
        <span class="stage-title" :style="{ color: stageColor }">
          {{ stageName }}
        </span>
        
        <div class="counter-badge">
          {{ itemsTotal }}
        </div>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: 'KanbanColumnHeader',
  
  props: {
    stageColor: {
      type: String,
      required: true
    },
    stageIcon: {
      type: String,
      required: true
    },
    stageName: {
      type: String,
      required: true
    },
    itemsTotal: {
      type: Number,
      required: true
    },
    storeStage: {
      type: String,
      default: ''
    },
    stageData: {
      type: Object,
      required: true
    }
  },

  methods: {
    handleEditClick() {
      this.$WiEditDialog({
        wiConfig: this.storeStage,
        onSubmit: () => this.$emit('refresh'),
        data: this.stageData
      })
    }
  }
}
</script>

<style scoped>
.kanban-column-header {
  border-radius: 8px 8px 0 0 !important;
  height: 40px !important;
  margin-bottom: 1px;
  z-index: 1;
}

.kanban-column-header >>> .v-toolbar__content {
  padding: 0 !important;
  height: 40px !important;
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 8px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.stage-icon {
  font-size: 20px !important;
}

.info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.stage-title {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.counter-badge {
  background-color: #f5f5f5;
  color: #424242;
  font-size: 12px;
  font-weight: 600;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.edit-button {
  margin: 0 !important;
  height: 32px !important;
  width: 32px !important;
}

.edit-button:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Ajuste para o conteúdo do toolbar do Vuetify */
.v-toolbar__content {
  padding: 0 !important;
  height: 48px !important;
}
</style> 