import { Model } from '@/default/model/Model'



export class TalkMenu extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: '37c54bf1',
      create: 'f89fe1b3',
      update: 'dddf7a44',
      delete: 'fb07a1b0'
    }
    this.name = 'menu'
    this.title = {
      list: 'Chatbots',
      edit: {
        insert: 'Criar Chatbot',
        update: 'Editar Chatbot'
      }
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do chatbot',
      fields: ['name']
    }
    this.apiUrl = 'talks/menu'
    this.apiParams = {
      limit: 10,
      order: 'created_at,desc'
    }
    this.icon = 'smart_toy'
    this.redirectOnSubmit = false

    this.maxWidth = '850px'

    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkMenus/TalkMenus')

    this.fieldsEdit = {
      header1: this.fieldSubHeader({
        text: 'Informe nome, tipo de Chatbot e selecione o robô.'
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        required: true,
        size: form => form.type == 8 ? ['xs12', 'sm12', 'md3', 'lg3'] : ['xs12', 'sm12', 'md6', 'lg6']
      }),
      type: this.fieldSelect({
        value: 'type',
        text: 'Tipo de Chatbot',
        options: [
          {
            value: 8,
            text: 'Chatbot'
          },
          // {
          //   value: 2,
          //   text: 'Texto + Opções'

          // },
          // {
          //   value: 3,
          //   text: 'Texto + Encerrar atendimento'  
          // },
          {
            value: 4,
            text: 'Texto + Encaminhar para equipe'
          },
          {
            value: 5,
            text: 'Mensagem de saudação'
          },
          // {
          //   value: 10,
          //   text: 'Agente Cred FGTS FACTA'
          // },
          {
            value: 11,
            text: 'Agente Cred Oferta 2 Etapas'
          }
        ],
        size: form => form.type == 8 ? ['xs12', 'sm12', 'md3', 'lg3'] : ['xs12', 'sm12', 'md6', 'lg6'],
        required: true
      }),
      integration_id: this.fieldWiListDialog({
        value: 'integration_id',
        text: 'Chatbot',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-flow',
        show: 'integration.name',
        showField: (form) => (form.type == 8),
        callbackFields: [
          {
            get: 'external_code',
            set: 'external_code'
          },
          {
            get: 'name',
            set: 'integration_name'
          }
        ]
      }),
      // external_code: this.fieldSelectApi({
      //   value: 'external_code',
      //   text: 'Chatbot',
      //   size: ['xs12', 'sm12', 'md4', 'lg4'],
      //   api: {
      //     url: 'talks/chatbot'
      //   },
      //   show: 'name',
      //   showField: (form) => (form.type == 8)
      // }),
      header3: this.fieldSubHeader({
        text: 'Informe a equipe/Departamento que receberá o atendimento.',
        showField: (form) => (form.type == 4)
      }),
      team_id: this.fieldWiListDialog({
        value: 'team_id',
        text: 'Equipe/Departamento',
        required: true,
        showField: (form) => (form.type == 4),
        wiConfig: 'team',
        show: 'team.name',
        icon: 'persons'
      }),
      header2: this.fieldSubHeader({
        text: 'Informe a mensagem que será exibida no menu.',
        showField: (form) => (form.type == 5 || form.type == 4)
      }),
      message: this.fieldTextArea({
        value: 'message',
        text: 'Texto/Mensagem',
        required: true,
        showField: (form) => (form.type == 5 || form.type == 4)
      }),
      ...this.type11EditFields
    }
    this.fieldsList = {
      id: this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      name: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      type: this.fieldList({
        value: 'type',
        text: 'Tipo',
        align: 'center'
      })
    }
    this.setFields(this.fieldsEdit)
    this.setFields(this.fieldsList)
  }

  type11EditFields = {
    // Resposta 1 - positiva - Modelo 1
    // Resposta 1 - positiva - Modelo 2
    // Resposta 1 - negativa - Modelo 1
    // Resposta 1 - negativa - Modelo 2
    // Resposta 1 - positiva - tag
    // Resposta 1 - negativa - tag
    // Resposta 2 - positiva - Modelo 1
    // Resposta 2 - positiva - Modelo 2
    // Resposta 2 - negativa - Modelo 1
    // Resposta 2 - negativa - Modelo 2
    // Resposta 2 - positiva - tag
    // Resposta 2 - negativa - tag
    // Canais para chamar o contato
    type11_header0: this.fieldSubHeader({
      text: 'Respostas para a primeira mensagem',
      showField: (form) => (form.type == 11),
      style: {
        padding: '4px',
        background: 'black',
        color: 'white'
      }
    }),
    type11_header1: this.fieldSubHeader({
      text: 'Resposta se o contato responder "Sim" à primeira mensagem',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      style: {
        background: 'green',
        color: 'white',
        fontSize: '12px'
      },
      showField: (form) => (form.type == 11)
    }),
    type11_header2: this.fieldSubHeader({
      text: 'Resposta se o contato responder "Não" à primeira mensagem',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      style: {
        background: 'red',
        color: 'white',
        fontSize: '12px'
      },
      showField: (form) => (form.type == 11)
    }),
    type11_reply_1_positive_model_1: this.fieldTextArea({
      value: 'config.reply_1_positive_model_1',
      text: 'Resposta 1 - positiva',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => (form.type == 11)
    }),
    type11_reply_1_negative_model_1: this.fieldTextArea({
      value: 'config.reply_1_negative_model_1',
      text: 'Resposta 1 - negativa',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => (form.type == 11)
    }),
    // type11_reply_1_positive_model_2: this.fieldTextArea({
    //   value: 'config.reply_1_positive_model_2',
    //   text: 'Resposta 1 - positiva - Modelo 2',
    //   required: false,
    //   size: ['xs12', 'sm12', 'md6', 'lg6'],
    //   showField: (form) => (form.type == 11)
    // }),
    // type11_reply_1_negative_model_2: this.fieldTextArea({
    //   value: 'config.reply_1_negative_model_2',
    //   text: 'Resposta 1 - negativa - Modelo 2',
    //   required: false,
    //   size: ['xs12', 'sm12', 'md6', 'lg6'],
    //   showField: (form) => (form.type == 11)
    // }),
    type11_reply_1_positive_tag: this.fieldSelectApi({
      value: 'config.reply_1_positive_tag',
      text: 'Tag para resposta 1 positiva',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'register/tag',
        params: {
          fields: 'id,name,color,icon',
        }
      },
      show: 'name',
      color: 'color',
      icon: 'icon',
      multiple: false,
      showField: (form) => (form.type == 11)
    }),
    type11_reply_1_negative_tag: this.fieldSelectApi({
      value: 'config.reply_1_negative_tag',
      text: 'Tag para resposta 1 negativa',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'register/tag',
        params: {
          fields: 'id,name,color,icon',
        }
      },
      show: 'name',
      color: 'color',
      icon: 'icon',
      multiple: false,
      showField: (form) => (form.type == 11)
    }),
    type11_header3: this.fieldSubHeader({
      text: 'Respostas para a segunda mensagem',
      showField: (form) => (form.type == 11),
      style: {
        padding: '4px',
        background: 'black',
        color: 'white'
      }
    }),
    type11_header4: this.fieldSubHeader({
      text: 'Resposta se o contato responder "Sim" à segunda mensagem',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      style: {
        background: 'green',
        color: 'white',
        fontSize: '12px'
      },
      showField: (form) => (form.type == 11)
    }),
    type11_header5: this.fieldSubHeader({
      text: 'Resposta se o contato responder "Não" à segunda mensagem',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      style: {
        background: 'red',
        color: 'white',
        fontSize: '12px'
      },
      showField: (form) => (form.type == 11)
    }),
    type11_reply_2_positive_model_1: this.fieldTextArea({
      value: 'config.reply_2_positive_model_1',
      text: 'Resposta 2 - positiva',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => (form.type == 11)
    }),
    type11_reply_2_negative_model_1: this.fieldTextArea({
      value: 'config.reply_2_negative_model_1',
      text: 'Resposta 2 - negativa',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => (form.type == 11)
    }),
    type11_reply_2_positive_tag: this.fieldSelectApi({
      value: 'config.reply_2_positive_tag',
      text: 'Tag para resposta 2 positiva',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'register/tag',
        params: {
          fields: 'id,name,color,icon',
        }
      },
      show: 'name',
      color: 'color',
      icon: 'icon',
      multiple: false,
      showField: (form) => (form.type == 11)
    }),
    type11_reply_2_negative_tag: this.fieldSelectApi({
      value: 'config.reply_2_negative_tag',
      text: 'Tag para resposta 2 negativa',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'register/tag',
        params: {
          fields: 'id,name,color,icon',
        }
      },
      show: 'name',
      color: 'color',
      icon: 'icon',
      multiple: false,
      showField: (form) => (form.type == 11)
    }),
    type11_header7: this.fieldSubHeader({
      text: 'Canais para chamar o contato em caso de resposta positiva',
      showField: (form) => (form.type == 11),
      style: {
        padding: '4px',
        background: 'black',
        color: 'white'
      }
    }),
    type11_channels_positive: this.fieldSelectApi({
      value: 'config.channels_positive',
      text: 'Canais de atendimento',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      api: {
        url: 'talk/channel',
        params: {
          fields: 'id,uuid,name,type_id,status_id',
        }
      },
      show: 'name',
      multiple: true,
      color: 'status_color',
      icon: 'status_icon',
      showField: (form) => (form.type == 11)
    }),
    type11_header8: this.fieldSubHeader({
      text: `
        Insira abaixo a mensagem que será enviada para o 
        contato no canal selecionado. Se inserir mais de uma mensagem, 
        o sistema irá enviar de forma aleatória cada hora uma das mensagens.
      `,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      style: {
        background: 'black',
        color: 'white',
        fontSize: '12px'
      },
      showField: (form) => (form.type == 11)
    }),
    type11_other_channel_message_1: this.fieldTextArea({
      value: 'config.other_channel_message_1',
      text: 'Mensagem enviada 1',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => (form.type == 11)
    }),
    type11_other_channel_message_2: this.fieldTextArea({
      value: 'config.other_channel_message_2',
      text: 'Mensagem enviada 2',
      required: false,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => (form.type == 11)
    }),
    type11_other_channel_action_options: this.fieldSelect({
      value: 'config.other_channel_action_options',
      text: 'Ação que será executada após a mensagem ser enviada',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      options: [
        {
          value: 1,
          text: 'Distribuir para uma equipe'
        },
        // {
        //   value: 2,
        //   text: 'Encaminhar para um agente específico'
        // },
        // {
        //   value: 3,
        //   text: 'Encerrar atendimento'
        // }
      ],
      showField: (form) => (form.type == 11)
    }),
    type11_other_channel_team_id: this.fieldSelectApi({
      value: 'config.other_channel_team_id',
      text: 'Equipe para distribuir',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      api: {
        url: 'register/team',
        params: {
          fields: 'id,name',
        }
      },
      show: 'name',
      required: true,
      showField: (form) => (form.type == 11 && form['config.other_channel_action_options'] == 1)
    }),
    type11_other_channel_agent_id: this.fieldSelectApi({
      value: 'config.other_channel_agent_id',
      text: 'Agente para encaminhar',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      api: {
        url: 'register/person/collaborator',
        params: {
          fields: 'id,name',
        }
      },
      show: 'name',
      required: true,
      showField: (form) => (form.type == 11 && form['config.other_channel_action_options'] == 2)
    })
  }
}
