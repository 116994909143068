<template>
  <v-card
    v-if="hasData"
    class="elevation-10 mt-4"
    style="border-radius: 10px;"
  >
    <div style="padding: 10px; display: flex; align-items: center;">
      <v-icon>monetization_on</v-icon>
      <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.title'"></h4>
    </div>
    <v-card-text>
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OrdersAmountYearlyComparisonChart',
  
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  
  data: () => ({
    chartOptions: {
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 5
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Meses'
        }
      },
      yaxis: {
        title: {
          text: 'Valor Total (R$)'
        },
        labels: {
          formatter: function(value) {
            return new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(value)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(value)
          }
        }
      },
      colors: ['#4CAF50', '#81C784', '#F44336', '#EF9A9A', '#FF9800', '#FFCC80', '#2196F3', '#90CAF9'],
      legend: {
        position: 'top'
      }
    },
    chartSeries: []
  }),
  
  computed: {
    hasData() {
      return this.data && 
             this.data.current_year && 
             this.data.previous_year
    }
  },
  
  methods: {
    formatMonth(monthStr) {
      const [year, month] = monthStr.split('-')
      const date = new Date(year, parseInt(month) - 1)
      
      const monthName = date.toLocaleString('pt-BR', { month: 'short' })
        .replace('.', '')
        .charAt(0).toUpperCase() + 
        date.toLocaleString('pt-BR', { month: 'short' })
          .replace('.', '')
          .slice(1)
      
      return monthName
    },
    
    updateChart() {
      if (!this.hasData) return
      
      // Obter os meses do ano atual
      const categories = this.data.current_year.total.map(item => this.formatMonth(item.month))
      
      // Atualizar as opções do gráfico com as categorias dinâmicas
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories,
          title: {
            text: 'Meses'
          }
        }
      }
      
      this.chartSeries = [
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.currentYear.done'),
          data: this.data.current_year.done.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.previousYear.done'),
          data: this.data.previous_year.done.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.currentYear.canceled'),
          data: this.data.current_year.canceled.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.previousYear.canceled'),
          data: this.data.previous_year.canceled.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.currentYear.opened'),
          data: this.data.current_year.opened.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.previousYear.opened'),
          data: this.data.previous_year.opened.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.currentYear.total'),
          data: this.data.current_year.total.map(item => item.amount)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersAmountYearlyComparison.previousYear.total'),
          data: this.data.previous_year.total.map(item => item.amount)
        }
      ]
    }
  },
  
  watch: {
    data: {
      handler: 'updateChart',
      immediate: true
    }
  }
}
</script> 