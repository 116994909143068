import url from '@/default/store/modules/url/url'
import WiseView from '@/default/store/modules/system/systems/wise-view'
import SisPocos from '@/default/store/modules/system/systems/sis-pocos'
import WiseMessage from '@/default/store/modules/system/systems/wise-message'
import WiseControl from '@/default/store/modules/system/systems/wise-control'
import WiseProvider from '@/default/store/modules/system/systems/wise-provider'
import WiseJuridical from '@/default/store/modules/system/systems/wise-juridical'

const systems = {
  'wise-view': WiseView,
  'sis-pocos': SisPocos,
  'wise-message': WiseMessage,
  'wise-control': WiseControl,
  'wise-provider': WiseProvider,
  'wise-juridical': WiseJuridical,
}

const system = systems[url.state.system]

system.version = '5.4.158'
system.lastUpdate = '08/02/2025'
system.versionApi = null
system.log = url.state.log

export default {
  namespaced: true,
  state: system,
  mutations: {
    SET_VERSION_API (state, data) {
      state.versionApi = data
    }
  }
}
