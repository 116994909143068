<template>
  <v-card
    v-if="hasData"
    class="elevation-10 mt-4"
    style="border-radius: 10px;"
  >
    <div style="padding: 10px; display: flex; align-items: center;">
      <v-icon>bar_chart</v-icon>
      <h4 class="ml-2" v-t="'modules.serviceDesk.pages.orderDashboard.ordersByStage.title'"></h4>
    </div>
    <v-card-text>
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OrdersByStageChart',
  
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  
  data: () => ({
    chartOptions: {
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 5
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: 'Quantidade de O.S'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " O.S"
          }
        }
      },
      colors: ['#4CAF50', '#F44336', '#FF9800', '#2196F3'],
      legend: {
        position: 'top'
      }
    },
    chartSeries: []
  }),
  
  computed: {
    hasData() {
      return this.data && Object.keys(this.data).length > 0
    }
  },
  
  methods: {
    formatMonth(monthStr) {
      const [year, month] = monthStr.split('-')
      return `${month}/${year}`
    },
    
    updateChart() {
      if (!this.hasData) return
      
      // Atualizar categorias (meses)
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: this.data.total.map(item => this.formatMonth(item.month))
        }
      }
      
      // Atualizar séries
      this.chartSeries = [
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersByStage.done'),
          data: this.data.done.map(item => item.count)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersByStage.canceled'),
          data: this.data.canceled.map(item => item.count)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersByStage.opened'),
          data: this.data.opened.map(item => item.count)
        },
        {
          name: this.$t('modules.serviceDesk.pages.orderDashboard.ordersByStage.total'),
          data: this.data.total.map(item => item.count)
        }
      ]
    }
  },
  
  watch: {
    data: {
      handler: 'updateChart',
      immediate: true
    }
  }
}
</script> 