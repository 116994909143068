<template>
  <div>
    <v-toolbar dense :color="!$wiDark ? 'primary' : 'dark'" style="color: white; z-index: 1;">
      <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title v-if="execution && execution.process">
        {{ execution.process.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <label v-if="!loading && execution.status" class="process-toolbar__status" :style="{background: execution.status.color}">
        <v-icon color="white">{{execution.status.icon}}</v-icon>
        <label> {{execution.status.name}} </label>
      </label>
      <v-btn small outline class="hidden-xs-only hidden-sm-only pulse-button" color="white" @click="
        $WiHelpDialog({
          title: 'Por favor, descreva abaixo o que acontece com este processo:'
        })
      ">
        <v-icon>report</v-icon>
        <span style="padding-left: 5px;"> Reportar</span>
      </v-btn>
      <v-btn small outline class="hidden-xs-only hidden-sm-only" color="white" @click="stageFlowchartDialog = true">
        <v-icon>account_tree</v-icon>
        <span style="padding-left: 5px;"> Fluxo de etapas</span>
      </v-btn>
      <v-btn :loading="loading" icon @click="refreshExecution()">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading || stageLoading" :indeterminate="true" style="margin: 0 !important;"></v-progress-linear>
    <StageFlowchartDialog
      v-if="execution && execution.process_id"
      v-model="stageFlowchartDialog"
      :loading="loading"
      :processId="execution.process_id"
    ></StageFlowchartDialog>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import StageFlowchartDialog from '@/modules/service-desk/pages/Process/StageFlowchartDialog'
  export default {
    name: 'ProcessTollbar',
    data: () => ({
      stageFlowchartDialog: false
    }),
    computed: {
      ...mapState('ProcessExecution', ['execution', 'loading']),
      ...mapGetters('ProcessExecution', ['stageLoading'])
    },
    methods: {
      test: function () {
        // uuid
      },
      ...mapActions('ProcessExecution', ['refreshExecution'])
    },
    components: {
      StageFlowchartDialog
    }
  }
</script>

<style scoped>
  .process-toolbar__status {
    font-size: 14px;
    color: white;
    padding: 0px 10px;
    border-style: dashed;
    border-color: white;
    border-width: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  .pulse-button {
    animation: pulse 2s infinite;
  }
</style>