var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-detail',{attrs:{"title":"Detalhes do Chamado","apiUrl":'service-desk/ticket',"apiParams":'?join=interacao',"notShowToolbar":false},scopedSlots:_vm._u([{key:"toolbarItensBefore",fn:function(dados){return [_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': 'a5243554'}),expression:"{'id': 'a5243554'}"}],staticClass:"hidden-xs-only hidden-sm-only",attrs:{"small":"","outline":"","color":"white","disabled":dados.loading || dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3},on:{"click":function($event){return _vm.$WiEditDialog({wiConfig: 'chamados-anotacao', onSubmit: dados.refresh, data: {chamado_dados_id: dados.content.id}})}}},[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Adicionar Anotação")])],1),_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': 'a5243554'}),expression:"{'id': 'a5243554'}"}],staticClass:"hidden-xs-only hidden-sm-only",attrs:{"icon":"","disabled":dados.loading || dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3},on:{"click":function($event){return _vm.editSettings(dados.content, dados.refresh)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("tune")])],1),_c('v-btn',{directives:[{name:"prms",rawName:"v-prms",value:({'id': '8d7c5c53'}),expression:"{'id': '8d7c5c53'}"}],staticClass:"hidden-xs-only hidden-sm-only",attrs:{"icon":""}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":dados.loading || dados.content.chamado_status_id == 2 || dados.content.chamado_status_id == 3}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("settings")])],1)]}}],null,true)},[_c('v-list',[_c('v-subheader',[_vm._v("Ações do chamado")]),_c('v-divider'),_c('v-list-tile',{on:{"click":function($event){return _vm.handleRemove(dados.content.id, 'devices')}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("devices")])],1),_c('v-list-tile-title',[_vm._v("Remover todos os dispositivos deste chamado")])],1),_c('v-list-tile',{on:{"click":function($event){return _vm.handleRemove(dados.content.id, 'networks')}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("wifi")])],1),_c('v-list-tile-title',[_vm._v("Remover todas as redes deste chamado")])],1),_c('v-list-tile',{on:{"click":function($event){return _vm.handleRemove(dados.content.id, 'interfaces')}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("settings_ethernet")])],1),_c('v-list-tile-title',[_vm._v("Remover todas as interfaces deste chamado")])],1),_c('v-list-tile',{on:{"click":function($event){return _vm.handleRemove(dados.content.id, 'all')}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("delete_sweep")])],1),_c('v-list-tile-title',[_vm._v("Remover todos os dispositivos, redes e interfaces")])],1)],1)],1)],1)]}},{key:"default",fn:function(dados){return [(dados.content.assunto_chamado)?_c('div',{staticStyle:{"padding":"20px !important"}},[_c('v-card',{staticClass:"elevation-10"},[_c('v-list-tile',{attrs:{"avatar":""}},[_c('v-list-tile-content',[_c('v-list-tile-sub-title',[_c('span',{staticStyle:{"color":"gray"}},[_vm._v("Título/Assunto:")])]),_c('v-list-tile-title',[_c('strong',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(dados.content.assunto_chamado)+" ")])])],1),_c('v-list-tile-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$WiEditDialog({
                'wiConfig': 'ticket-title',
                data: {
                  id: dados.content.id,
                  assunto_chamado: dados.content.assunto_chamado,
                },
                onSubmit: dados.refresh
              })}}},[_c('v-icon',[_vm._v("edit")])],1)],1)],1)],1)],1):_vm._e(),_c('div',{staticStyle:{"padding":"20px !important"}},[_c('ticket-info',{attrs:{"dados":dados}})],1),_c('div',{staticClass:"padding_chamado",staticStyle:{"padding-bottom":"50px"}},[_c('v-expansion-panel',{attrs:{"popout":"","expand":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel-content',{staticClass:"elevation-17",attrs:{"value":"teste3"}},[_c('template',{slot:"header"},[_c('strong',{staticStyle:{"color":"gray"}},[_c('v-icon',[_vm._v("timeline")]),_c('span',[_vm._v(" Linha do tempo de interações")])],1)]),(_vm.panel[0])?_c('div',[_c('v-divider'),_c('chamado-time-line',{attrs:{"dados":dados}})],1):_vm._e()],2)],1)],1),_c('v-btn',{staticClass:"white--text hidden-md-only hidden-lg-only hidden-xl-only elevation-17",attrs:{"color":"green","fab":"","fixed":"","bottom":"","right":""},on:{"click":function($event){return _vm.$WiEditDialog({wiConfig: 'chamados-anotacao', onSubmit: dados.refresh, data: {chamado_dados_id: dados.content.id}})}}},[_c('v-icon',[_vm._v("add")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }