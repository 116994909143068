const getCliOrForTicket = personType => {
  switch (personType) {
    case 'customer':
      return 'cli'
    case 'provider':
      return 'for'
  }
  return null
}

export default [
  {
    value: 'id',
    text: 'ID',
    list: true,
    search: true
  },
  {
    text: 'Informações básicas desta transação financeira.',
    type: 'subheader',
    edit: true
  },
  {
    value: 'in_out',
    text: 'Tipo',
    align: 'center',
    type: 'select',
    list: true,
    edit: {
      insert: true,
      update: false
    },
    required: true,
    size: ['xs12', 'sm12', 'md2', 'lg2'],
    options: [{value: 1, text: 'Entrada/Receita'}, {value: 2, text: 'Saída/Despesa'}],
    showField: (form) => { return !form.hide_in_out }
  },
  {
    value: 'number_this',
    text: 'Parcela',
    list: true,
    align: 'left'
  },
  {
    value: 'amount_total',
    text: 'Valor.',
    list: true
  },
  {
    value: 'date_receive',
    text: 'Venc./Pgmt.',
    list: true
  },
  {
    value: 'date_validity',
    text: 'Vencimento',
    type: 'date',
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md2', 'ls2']
  },
  {
    value: 'amount_initial',
    text: 'Valor/Subtotal',
    type: 'money',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'lg2']
  },
  {
    value: 'status',
    text: 'Status',
    align: 'left',
    type: 'select',
    list: true,
    edit: true,
    required: true,
    size: (form) => { return form.id ? (form.status === 2 ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md8', 'lg8']) : (form.status === 2 ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md3', 'lg3']) },
    options: [{value: 1, text: 'Aguardando'}, {value: 2, text: 'Pago/Recebido'}, {value: '0', text: 'Cancelado'}]
  },
  {
    value: 'date_receive',
    text: 'Data de Pagamento',
    type: 'date',
    edit: true,
    required: true,
    size: ['xs12', 'sm12', 'md2', 'lg2'],
    showField: (form) => { return form.status === 2 }
  },
  {
    value: 'finance_account_id',
    text: 'Conta Financeira',
    type: 'db-autocomplete',
    autocomplete: true,
    required: true,
    list: false,
    edit: {
      insert: true,
      update: false
    },
    apiUrl: 'finance/account',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: (form) => { return form.status === 2 ? ['xs12', 'sm12', 'md2', 'lg2'] : (form.hide_in_out ? ['xs12', 'sm12', 'md5', 'lg5'] : ['xs12', 'sm12', 'md3', 'lg3']) }
  },
  {
    text: 'Informações de identificação da transação financeira.',
    type: 'subheader',
    edit: true
  },
  {
    value: 'description',
    text: 'Descrição',
    type: 'text',
    list: true,
    edit: true,
    search: true,
    align: 'center',
    sortable: false,
    size: (form) => { return form.in_out ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md3', 'lg3'] }
  },
  {
    value: 'finance_form_payment_id',
    text: 'Forma Pgmt.',
    type: 'db-autocomplete',
    autocomplete: true,
    align: 'center',
    list: true,
    edit: true,
    apiUrl: 'finance/form-payment',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: (form) => { return form.in_out ? ['xs12', 'sm12', 'md3', 'lg3'] : ['xs12', 'sm12', 'md4', 'lg4'] }
  },
  {
    value: 'finance_category_id',
    text: 'Categoria de Receita',
    type: 'db-autocomplete',
    autocomplete: true,
    list: false,
    edit: true,
    apiUrl: 'finance/category/in',
    apiParams: {order: 'level,asc;name,asc'},
    show: 'hierarchical_name',
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    showField: (form) => { return form.in_out === 1 }
  },
  {
    value: 'finance_category_id',
    text: 'Categoria de Despesa',
    type: 'db-autocomplete',
    autocomplete: true,
    list: false,
    edit: true,
    apiUrl: 'finance/category/out',
    apiParams: {order: 'level,asc;name,asc'},
    show: 'hierarchical_name',
    size: ['xs12', 'sm12', 'md3', 'lg3'],
    showField: (form) => { return form.in_out === 2 }
  },
  {
    value: 'finance_account_id',
    text: 'Categoria/Conta',
    type: 'text',
    list: true,
    edit: false,
    search: true,
    size: (form) => { return form.in_out ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md3', 'lg3'] }
  },
  {
    value: 'invoice',
    text: 'Nota Fiscal',
    type: 'text',
    list: false,
    edit: true,
    search: true,
    size: (form) => { return form.in_out ? ['xs12', 'sm12', 'md2', 'lg2'] : ['xs12', 'sm12', 'md3', 'lg3'] }
  },
  {
    value: 'advanced',
    text: 'Opções avançadas.',
    size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
    type: 'switch',
    edit: true
  },
  {
    text: 'Valores e datas complementares.',
    type: 'subheader',
    edit: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    showField: (form) => { return form.advanced }
  },
  {
    text: 'Vincular a um Centro de Custo, Cliente, Fornecedor ou Colaborador.',
    type: 'subheader',
    edit: true,
    size: ['xs12', 'sm12', 'md6', 'lg6'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'date_competence',
    text: 'Data de Competência',
    type: 'date',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'ls2', 'xl2'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'amount_discount',
    text: 'Desconto',
    type: 'money',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'ls2', 'xl2'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'amount_addition',
    text: 'Acréscimo',
    type: 'money',
    edit: true,
    size: ['xs12', 'sm12', 'md2', 'ls2', 'xl2'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'finance_cost_center_id',
    text: 'Centro de Custo',
    type: 'db-autocomplete',
    autocomplete: true,
    list: false,
    edit: true,
    apiUrl: 'finance/cost-center',
    apiParams: {fields: 'id,name', order: 'name,asc'},
    show: 'name',
    size: ['xs12', 'sm12', 'md4', 'lg4'],
    showField: (form) => { return form.advanced }
  },
  {
    value: 'person_make',
    text: 'Vincular Fornecedor, Cliente/Colaborador.',
    size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
    type: 'switch',
    edit: true,
    showField: (form) => { return form.advanced }
  },
  {
    text: 'Vincular esta transação a um Cliente, Fornecedor ou a um Colaborador.',
    type: 'subheader',
    edit: true,
    showField: (form) => { return form.advanced && form.person_make }
  },
  {
    value: 'person_type',
    text: 'Cliente, Fornecedor ou Colaborador',
    align: 'center',
    type: 'select',
    edit: true,
    required: true,
    size: (form) => { return form.person_type ? ['xs12', 'sm12', 'md4', 'lg4'] : ['xs12', 'sm12', 'md12', 'lg12'] },
    options: [{value: 'customer', text: 'Vincular a um Cliente'}, {value: 'provider', text: 'Vincular a um Fornecedor'}, {value: 'collaborator', text: 'Vincular a um Colaborador'}],
    showField: (form) => { return form.advanced && form.person_make }
  },
  {
    value: 'person_id',
    text: 'Cliente*',
    autocomplete: true,
    required: true,
    size: form => (form.person_id ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md8', 'lg8']),
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'customer',
    show: 'person.first_name',
    icon: 'person_outline',
    showField: (form) => { return form.advanced && form.person_make && form.person_type === 'customer' }
  },
  {
    value: 'person_id',
    text: 'Fornecedor*',
    autocomplete: true,
    required: true,
    size: form => (form.person_id ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md8', 'lg8']),
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'provider',
    show: 'person.first_name',
    icon: 'person_outline',
    showField: (form) => { return form.advanced && form.person_make && form.person_type === 'provider' }
  },
  {
    value: 'person_id',
    text: 'Colaborador*',
    autocomplete: true,
    required: true,
    size: form => (form.person_id ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md8', 'lg8']),
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'collaborator',
    show: 'person.first_name',
    icon: 'person_outline',
    showField: (form) => { return form.advanced && form.person_make && form.person_type === 'collaborator' }
  },
  {
    value: 'negatived',
    text: 'Negativado',
    size: [ 'xs12', 'sm12', 'md2', 'lg2' ],
    type: 'switch',
    edit: true,
    showField: form => (form.id && form.advanced && form.person_id)
  },
  {
    value: 'ticket_id',
    text: 'Vincule a um chamado*',
    required: true,
    size: ['xs12', 'sm12', 'md12', 'lg12'],
    type: 'wi-list-dialog',
    edit: true,
    wiConfig: 'chamado',
    show: 'ticket.id',
    icon: 'task',
    wiEditData: form => ({
      person_id: form.person_id,
      cli_ou_for: getCliOrForTicket(form.person_type)
    }),
    filter: form => ({
      person_id: form.person_id,
      where: 'stage,1'
    }),
    showField: form => (form.id && form.advanced && form.person_make && form.negatived)
  },
  // {
  //   text: 'Informe se deseja repetir esta transação.',
  //   type: 'subheader',
  //   edit: {
  //     insert: true
  //   }
  // },
  // {
  //   value: 'repeat',
  //   text: 'Repetir transação?',
  //   required: false,
  //   size: (form) => { return form.repeat ? ['xs12', 'sm12', 'md6', 'lg6'] : ['xs12', 'sm12', 'md12', 'lg12'] },
  //   type: 'select',
  //   options: [{value: 0, text: 'Não, transação acontece apenas uma vez.'}, {value: 1, text: 'Repetir transação por um número de vezes'}],
  //   edit: {
  //     insert: true
  //   },
  //   showField: (form) => { return form.advanced }
  // },
  // {
  //   value: 'number_total',
  //   text: 'Quantidade de repetições',
  //   type: 'int',
  //   edit: {
  //     insert: true
  //   },
  //   size: ['xs12', 'sm12', 'md3', 'lg3'],
  //   showField: (form) => { return form.advanced && form.repeat && form.repeat === 1 }
  // },
  // {
  //   value: 'number_period',
  //   text: 'Intervalo entre repetições',
  //   type: 'select',
  //   options: [{value: 'month', text: 'Repetir por mês'}, {value: 'days', text: 'Repetir por dia'}, {value: 'week', text: 'Repetir por semana.'}, {value: 'year', text: 'Repetir por ano.'}],
  //   edit: {
  //     insert: true
  //   },
  //   size: ['xs12', 'sm12', 'md3', 'lg3'],
  //   showField: (form) => { return form.advanced && form.repeat && form.repeat === 1 }
  // },
  {
    value: 'observation',
    text: 'Observações',
    type: 'textarea',
    edit: true
  },
  {
    value: 'id',
    text: 'Opções',
    list: true,
    align: 'center',
    sortable: false
  }
]
