<template>
  <v-list-tile-title>
    <!-- Operator Status -->
    <span v-if="[2, 3, 4].includes(status)">
      <strong
        v-if="status == 3"
        style="color: green;padding-right: 5px;"
        v-t="'modules.talks.pages.talkView.talks.labels.closed'"
      ></strong>
      <strong
        v-if="status == 4"
        style="color: red;padding-right: 5px;"
        v-t="'modules.talks.pages.talkView.talks.labels.cancelled'"
      ></strong>
      <span v-if="operator && operator.name">At: </span>
      <strong v-if="operator && operator.name">
        {{ operator.name | stringlength(22) }}
      </strong>
      <strong
        v-else-if="status == 2"
        style="color: rgb(223, 145, 0)"
        v-t="'modules.talks.pages.talkView.talks.labels.awaiting'"
      ></strong>
    </span>

    <!-- Chatbot Status -->
    <strong
      v-if="status == 1"
      style="color: rgb(1, 153, 190)"
      v-t="'modules.talks.pages.talkView.talks.labels.chatbot'"
    ></strong>
  </v-list-tile-title>
</template>

<script>
export default {
  name: 'TalkListItemStatus',
  
  props: {
    status: {
      type: [Number, String],
      required: true
    },
    operator: {
      type: Object,
      default: null
    }
  }
}
</script> 