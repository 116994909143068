<template>
  <v-container fluid class="pa-0 wn-register-copy-view">
    <!-- Título -->
    <v-layout v-if="selectedBank && showData" row wrap class="wn-register-copy-view__sticky-header">
      <v-flex xs12>
        <!-- <v-divider></v-divider> -->
        <v-toolbar dense :color="$wiDark ? 'dark' : 'white'" style="border-radius: 10px;">
          <v-toolbar-title class="register-copy-view__title">
            <v-img
              v-if="selectedBank"
              :src="selectedBank.logo"
              width="30" class="mr-2"
            ></v-img>
            <span>
              {{ selectedBank.name }}
            </span>
            <span v-if="data.external_code">
              - Proposta: {{ data.external_code }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="progressValue > 0"
            @click="resetCopiedFields"
            small outline
          >
            <v-icon>clear</v-icon>
            <span> Limpar campos copiados ({{ progressValue }}%)</span>
          </v-btn>
          <v-btn color="primary" small outline @click="editData">
            <v-icon>edit</v-icon>
            <span> Editar</span>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-progress-linear
          v-if="progressValue > 0"
          :value="progressValue"
          color="green"
          style="margin: 0px !important;"
        ></v-progress-linear>
      </v-flex>
    </v-layout>

    <!-- Campos dinâmicos -->
    <template v-if="selectedBank && showData">
      <v-layout 
        v-for="(row, rowIndex) in bankFields"
        :key="`row-${rowIndex}`"
        row 
        wrap 
        align-center 
        class="mt-3"
      >
        <v-flex 
          v-for="(field, fieldIndex) in row"
          :key="`field-${rowIndex}-${fieldIndex}`"
          v-bind="getFlexSizes(field.size)"
          :class="field.spacing || 'px-1'"
        >
          <register-copy-field
            :field="field"
            :data="data"
            @field-copied="handleFieldCopied"
          />
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
import banks from './index.js'
import RegisterCopyField from './RegisterCopyField.vue'
import BudgetFullMixin from '@/modules/sales/mixins/BudgetFullMixin'

export default {
  name: 'RegisterCopyView',
  components: {
    RegisterCopyField
  },
  props: {
    title: {
      type: String,
      default: 'Visualização de Registro'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showData: true,
      // selectedBank: banks.find(bank => bank.id == this.data.bank_product_id),
      copiedFields: {},
      totalFields: 0
    }
  },
  computed: {
    bankOptions() {
      return banks.map(bank => ({
        code: bank.code,
        label: bank.name
      }))
    },
    bankFields() {
      if (!this.selectedBank) return []
      return this.selectedBank.fields || []
    },
    totalCopyableFields() {
      return this.bankFields.flat().filter(field => 
        field.type !== 'header' && 
        field.type !== 'files' && 
        this.getValue(field)
      ).length
    },
    progressValue() {
      if (this.totalCopyableFields === 0) return 0
      const copiedCount = Object.keys(this.copiedFields).length
      return Math.round((copiedCount / this.totalCopyableFields) * 100)
    },
    selectedBank() {
      console.log('data', this.data?.category?.bank_id)
      return banks.find(
        bank => bank.id == this.data?.category?.bank_id
      )
    }
  },
  methods: {
    getFlexSizes(size) {
      if (!size || !Array.isArray(size)) {
        return { xs12: true, sm6: true } // valores padrão
      }
      
      return size.reduce((acc, item) => {
        acc[item] = true
        return acc
      }, {})
    },
    editData() {
      this.openBudgetFull(this.data)
      // this.$WiEditDialog({
      //   wiConfig: 'budget-full',
      //   data: {
      //     ...this.data,
      //     ...this.data.person,
      //     person_id: this.data.person_id,
      //     person: this.data.person
      //   },
      // })
    },
    getValue(field) {
      if (!field || !field.key) return null
      
      let value = null
      if (field.key.includes('.')) {
        const keys = field.key.split('.')
        let current = this.data
        for (const key of keys) {
          current = current?.[key]
          if (current === undefined) break
        }
        value = current
      } else {
        value = this.data?.[field.key]
      }
      
      return value || field.default || null
    },
    handleFieldCopied(fieldKey) {
      this.$set(this.copiedFields, fieldKey, true)
    },
    resetCopiedFields() {
      this.showData = false
      this.copiedFields = {}
      setTimeout(() => {
        this.showData = true
      }, 100);
    }
  },
  watch: {
    copiedFields: {
      handler(newVal) {
        console.log('copiedFields mudou:', newVal)
      },
      deep: true
    }
  },
  mixins: [BudgetFullMixin]
}
</script>
<style scoped>

.field-label {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.field-content {
  font-size: 1rem;
  padding: 4px 0;
  font-weight: 500;
}

.v-card {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.v-layout.column {
  height: 100%;
}

.wn-register-copy-view__sticky-header {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #f5f5f5;
}

.register-copy-view__title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
}
</style> 