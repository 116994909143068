import FinanceCategoryFields from '@/modules/finance/config/Finance/FinanceCategory/FinanceCategoryFields'

export default {
  id: '1b113a91',
  list: true,
  title: {
    list: 'Categorias de Receitas',
    edit: {
      insert: 'Categoria de Receita',
      update: 'Categoria de Receita'
    }
  },
  icon: 'category',
  apiUrl: 'finance/category/in',
  maxWidth: '370px',
  fields: FinanceCategoryFields
}
