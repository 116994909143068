<template>
  <div>
    <!-- Data de Entrega -->
    <template v-if="shouldShowDates && item.date_end_format">
      <v-divider></v-divider>
      <v-card-title
        v-if="isExpired"
        class="date-title"
      >
        <v-icon>date_range</v-icon>
        <label>
          <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.date_end_format}}
        </label>
        <v-spacer></v-spacer>
        <strong class="date-badge expired">
          A {{Math.abs(item.date_end_days)}} dia(s)
        </strong>
      </v-card-title>

      <v-card-title
        v-else-if="isWarning"
        class="date-title kanban-card-warning"
      >
        <v-icon>date_range</v-icon>
        <label>
          <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.date_end_format}}
        </label>
        <v-spacer></v-spacer>
        <strong class="date-badge kanban-card-warning">
          <span v-if="item.date_end_days">Em {{item.date_end_days}} dia(s)</span>
          <span v-else>Hoje</span>
        </strong>
      </v-card-title>

      <v-card-title
        v-else
        class="date-title"
      >
        <v-icon>date_range</v-icon>
        <label>
          <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.date_end_format}}
        </label>
        <v-spacer></v-spacer>
        <strong
          v-if="item.stage == 1"
          class="date-badge"
        >
          Em {{item.date_end_days}} dia(s)
        </strong>
      </v-card-title>
    </template>

    <!-- Data de Validade (apenas para orçamentos e cotações) -->
    <template v-if="shouldShowDates && showValidityDate">
      <v-divider v-if="item.date_validity_format"></v-divider>
      <v-card-title
        v-if="isValidityExpired"
        class="date-title"
      >
        <v-icon>date_range</v-icon>
        <label><strong>Validade</strong>: {{item.date_validity_format}}</label>
        <v-spacer></v-spacer>
        <strong class="date-badge expired">
          A {{Math.abs(item.date_validity_days)}} dia(s)
        </strong>
      </v-card-title>

      <v-card-title
        v-else-if="isValidityWarning"
        class="date-title kanban-card-warning"
      >
        <v-icon>date_range</v-icon>
        <label><strong>Validade</strong>: {{item.date_validity_format}}</label>
        <v-spacer></v-spacer>
        <strong class="date-badge kanban-card-warning">
          <span v-if="item.date_validity_days">
            Em {{item.date_validity_days}} dia(s)
          </span>
          <span v-else>Hoje</span>
        </strong>
      </v-card-title>

      <v-card-title
        v-else-if="item.date_validity_format"
        class="date-title"
      >
        <v-icon>date_range</v-icon>
        <label><strong>Validade</strong>: {{item.date_validity_format}}</label>
        <v-spacer></v-spacer>
        <strong 
          v-if="item.stage == 1" 
          class="date-badge"
        >
          Em {{item.date_validity_days}} dia(s)
        </strong>
      </v-card-title>
    </template>
  </div>
</template>

<script>
import { BUSINESS_TYPES } from '../config/businessConfig'
import url from '@/default/store/modules/url/url'

export default {
  name: 'BusinessCardDates',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true }
  },

  computed: {
    shouldShowDates() {
      if (this.config.type === BUSINESS_TYPES.BUDGET && 
          url.state.system === 'wise-message') {
        return this.item.advanced_view
      }
      return true
    },

    showValidityDate() {
      return this.config.type === BUSINESS_TYPES.BUDGET || 
             this.config.type === BUSINESS_TYPES.QUOTATION
    },

    isExpired() {
      return this.item.stage == 1 && this.item.date_end_format && this.item.date_end_days < 0
    },

    isWarning() {
      return this.item.stage == 1 && this.item.date_end_format && this.item.date_end_days < 2
    },

    isValidityExpired() {
      return this.item.stage == 1 && this.item.date_validity_format && this.item.date_validity_days < 0
    },

    isValidityWarning() {
      return this.item.stage == 1 && this.item.date_validity_format && this.item.date_validity_days < 2
    }
  }
}
</script>

<style scoped>
.date-title {
  padding: 5px;
}
.date-title.expired {
  color: red;
}
.date-title.kanban-card-warning {
  color: orange;
}
.date-badge {
  padding: 0px 5px;
  border-style: solid;
  border-width: 1px;
}
.date-badge.expired {
  border-color: red;
}
.date-badge.kanban-card-warning {
  border-color: rgb(245, 215, 159);
}
</style> 